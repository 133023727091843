import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "../../../app/store";
import API from "../../../app/api";
import TagDTO from "../../../types/TagDTO";
import {arraysAreEqual, filterUnique} from "../../../utils/array_utils";

export interface TagsState {
  tags: TagDTO[];
  selectedTags: number[],
  partyTags: TagDTO[],
  status: 'idle' | 'loading' | 'failed';
}

export const initialState: TagsState = {
  tags: [],
  selectedTags: [],
  partyTags: [],
  status: 'idle',
};

const loadTags = createAsyncThunk(
  'loadTags',
  async () => {
    return await API.getTagsList();
  }
);

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    changeTagSelection: (state, action: PayloadAction<TagDTO>) => {
      const tagId = action.payload.id;
      let selectedTags = state.selectedTags;

      if (selectedTags.includes(tagId)) {
        selectedTags.splice(selectedTags.indexOf(tagId), 1);
      } else {
        selectedTags.push(tagId);
      }
    },
    setPartyTags: (state, action: PayloadAction<TagDTO[] | undefined>) => {
      state.partyTags = action.payload ?? [];
    },
    setSelectedTags: (state, action: PayloadAction<number[] | undefined>) => {
      const newValue = action.payload ?? [];
      if(!arraysAreEqual(state.selectedTags, newValue)) {
        state.selectedTags = newValue;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadTags.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadTags.fulfilled, (state, action) => {
        state.status = 'idle';
        const tags = action.payload as [];
        state.tags = tags.map((item) => new TagDTO(item));
      })
      .addCase(loadTags.rejected, (state) => {
        state.status = 'failed';
      })
  },
});


export const actionsRef = {
  changeTagSelection: tagsSlice.actions.changeTagSelection,
  setPartyTags: tagsSlice.actions.setPartyTags,
  setSelectedTags: tagsSlice.actions.setSelectedTags,
  loadTags: loadTags,
}

export const stateRef = (state: RootState) => ({
  tags: state.tags.tags,
  selectedTags: state.tags.selectedTags,
  partyTags: state.tags.partyTags,
  status: state.tags.status,
  getDisplayTags: (): TagDTO[] => {
    const tags = state.tags;
    const selectedTags =tags.selectedTags;
    let tagIds = selectedTags.map((itemId) => itemId);
    tagIds = tagIds
      .concat(tags.partyTags.map((tag) => tag.id))
      .filter(filterUnique)
      .sort((a, b) => {
      //=======  "selected first"  =========
      // const selA = selectedTags.includes(a);
      // const selB = selectedTags.includes(b);
      // if(selA && !selB) {return -1;}
      // if(!selA && selB) {return 1;}
      if(a < b) {return -1;}
      return 1;
    });

    return tagIds.map((tagId) => tags.tags.find((tag) => tag.id === tagId)!);
  },
});

export const tagsConnector = connect(stateRef, actionsRef)
export type TagsProps = ConnectedProps<typeof tagsConnector>;

export default tagsSlice.reducer;
