import React from 'react';
import reportWebVitals from './reportWebVitals';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';

// Import internationalization
import './app/i18n';

import { StyledEngineProvider } from '@mui/material/styles';

import App from './App';

import { BrowserRouter } from 'react-router-dom';

import "@fontsource/maven-pro";
import "@fontsource/maven-pro/500.css";
import "@fontsource/maven-pro/600.css";
import "@fontsource/maven-pro/700.css";

import './index.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearch, faStar, faSliders } from '@fortawesome/free-solid-svg-icons'

library.add(faSearch, faStar, faSliders);

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </StyledEngineProvider>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
