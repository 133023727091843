import terms from "./TermsEN.json"
import RenderTerms from "../../components/renderTerms/RenderTerms";

const ExtenuatingCircumstancesPolicy = () => {
    const data = terms.extenuatingCircumstancesPolicy;

    return (
        <RenderTerms
            pageTitle={data.pageTitle}
            main={data.main as []}
        />
    )
}

export default ExtenuatingCircumstancesPolicy;