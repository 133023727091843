import React from 'react';
import { useTranslation } from 'react-i18next';
import {Moment} from 'moment';
import PartyOptionDTO from '../../types/PartyOptionDTO';
import {bookingPageConnector, BookingPageProps} from './bookingPageSlice';
import {TextInput, NumberInput} from '../../common/Input';
import PartyOptions from '../../common/PartyOptions';
import BookingExtraOption, {BookingExtraOptionDTO} from '../../types/PartyExtraOptionDTO';
import {NoMatch, Loading} from '../../pages/404/404';
import DateInput from '../../common/DateInput';

import styles from './BookingPage.module.css';
import {useNavigate, useParams} from 'react-router-dom';
import {parseDate} from "../../common/utils";
import {debugLog} from "../../utils/debug_utils";

const BookingPage = (state: BookingPageProps) => {

  const {partyId} = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const loadParty = state.loadParty;
  const errors = state.errors;

  React.useEffect(() => {
    if (state.selectedParty || !partyId) {
      return;
    }

    if (partyId)
      loadParty(+partyId);
  }, [partyId, loadParty, state.selectedParty])

  if (!partyId) {
    return <NoMatch/>;
  }

  if (!state.selectedParty) {
    return <Loading/>;
  }

  const validate = (): boolean => {
    let result = true;
    let newState = {...state.errors};

    if(!state.booking.dateOfParty){
      newState.dateOfParty = t('Date of the party is required');
      result = false;
    } else {
      newState.dateOfParty = undefined;
    }

    if(state.booking.numberOfKids < 1){
      newState.numberOfKids = t('Number of kids can be not less than 1');
      result = false;
    } else {
      newState.numberOfKids = undefined;
    }

    if(!state.booking.fullName){
      newState.fullName = t('Name is required');
      result = false;
    } else {
      newState.fullName = undefined;
    }

    if(!state.booking.agreement){
      newState.agreement = true;
      result = false;
    } else {
      newState.agreement = undefined;
    }

    if(!state.booking.email){
      newState.email = t('Contact email is required');
      result = false;
    } else {
      const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if(!expression.test(state.booking.email)){
        newState.email = t('Email is invalid');
        result = false;
      } else {
        newState.email = undefined;
      }
    }

    state.setErrors(newState);

    return result;
  }

  const submitBooking = async () => {
    if(!validate()){
      return;
    }

    let result: any = await state.submitBooking(state.booking);

    if (result.error != null) {
      debugLog(`${result.error.code}: ${result.error.message}`)
      alert(`${result.error.code}:\n${result.error.message}`);
    } else {
      navigate(`/party/${state.selectedParty?.id}/book/done`);
    }
  }

  const handleChange = (newValue: string | number | Moment | boolean, name: string) => {
    if(['email', 'fullName', 'numberOfKids', 'dateOfParty', 'nameChild', 'birthday', 'agreement'].indexOf(name) !== -1){
      state.setErrors({...state.errors, [name]: undefined});
    }

    if(name === 'dateOfParty'){
      state.setDateOfParty(newValue as Moment);
    } else if (name === 'birthday'){
      state.setBirthday(newValue as Moment);
    } else {
      state.setBooking({...state.booking, [name]: newValue});
    }
  }

  const setSelectedOption = (option: PartyOptionDTO) =>
    state.setBooking({...state.booking, selectedPartyOption: option, partyOptionId: option.id});

  const handleExtraOptionChange = (newValue: number, name: string) => {
    let id = +name;
    let updatedOptions = state.booking.extraOptions.map((option: BookingExtraOption) => {
      if (option.id !== id)
        return option;

      return {
        ...option,
        value: newValue
      }
    })
    state.setBooking({
      ...state.booking,
      extraOptions: updatedOptions
    })
  }

  const formatPrice = (price: number, priceType: 'pp' | 'va') =>
    priceType === 'pp' ? `€${price} p.p.` : `v.a. €${price}`;

  return (
    <div className={styles.bookingPage}>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{width: '1118px'}}>
          <div className={styles.pageTitle}>{t('Confirm and pay')}</div>

          <div className={styles.title}>{t('Your booking')}</div>
        </div>
      </div>
      <div className={styles.bookingData}>
        <div className={styles.inputsBlock}>
          <div>
            <DateInput title={t('Date of the party')} name="dateOfParty" value={parseDate(state.booking.dateOfParty)}
                       onChange={handleChange} pickerType='future' showTimePicker longDate
                       errorMessage={errors.dateOfParty}/>
            <NumberInput title={t('Number of kids')} name="numberOfKids" value={state.booking.numberOfKids}
                         onChange={handleChange} errorMessage={errors.numberOfKids} min={1}/>
            <PartyOptions title={t('Options')} partyOptions={state.selectedParty.options}
                          selectedOption={state.booking.selectedPartyOption} setSelectedOption={setSelectedOption}/>
          </div>

          <div>
            {/*<div className={styles.subTitle}>Information about the parent</div>*/}
            <div className={styles.subTitle}>{t('Contact information')}</div>
            <TextInput title={t('Your name')} name="fullName" value={state.booking.fullName} onChange={handleChange} errorMessage={errors.fullName}/>
            <TextInput title={t('Your email')} name="email" value={state.booking.email} onChange={handleChange} lastField errorMessage={errors.email}/>
            {/*<div style={{fontSize: '12px'}}>This email is already registered. You can <a href="/signup">Sign up</a></div>*/}
          </div>

          <div>
            <div className={styles.subTitle}>{t('Information about the child')}</div>
            <TextInput title={t('Name of birthday person')} name="nameChild" value={state.booking.nameChild}
                       onChange={handleChange} errorMessage={errors.nameChild}/>
            <DateInput title={t('Birthday date')} name="birthday" value={parseDate(state.booking.birthday)}
                       onChange={handleChange} lastField pickerType='past' errorMessage={errors.birthday}/>
          </div>

          <div>
            {state.booking.extraOptions && <div className={styles.subTitle}>{t('Event options')}</div>}
            {state.booking.extraOptions.map((value: BookingExtraOptionDTO, index: number) => {
              let lastField = index === state.booking.extraOptions.length - 1;
              return (
                <NumberInput title={`${value.title} (${formatPrice(value.price, value.priceType)})`} name={"" + value.id} value={value.value}
                             lastField={lastField} min={0} onChange={handleExtraOptionChange} key={`${index} ${value.id}`}/>
              )
            })}
          </div>
        </div>
        <div className={styles.checkoutBlock}>

          <div>
            {/*<CheckoutBlock booking={state.booking} selectedParty={state.selectedParty}/>*/}

            <div className={styles.bookButton}>
              <div style={{fontSize: '14px'}} className={errors.agreement ? styles.invalidText : ''}>
                <input type="checkbox" name='agreement' checked={state.booking.agreement} onChange={(event) => handleChange(event.target.value, event.target.name)}/>{t('I agree with KidsVenu processing my personal data')}</div>
              <button onClick={() => submitBooking()}>{t('Book')}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default bookingPageConnector(BookingPage);