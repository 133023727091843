import * as React from 'react';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/nl';
import {DateCalendar} from '@mui/x-date-pickers';
import moment, {Moment} from 'moment';
import './DatePicker.css';
import {combineDateTime, dateFormat, getSlot, timeFormat} from "./utils";

type TimeSlotProps = {
  slot: string;
  selected: boolean;
}

const TimeSlot = ({slot, selected, onClickHandler}: TimeSlotProps & any) => {
  const className = `TimeSlot ${selected ? 'selected' : ''}`;
  return (
    <div className={className} onClick={onClickHandler}>{slot}</div>
  )
}

type DatePickerProps = {
  value?: Moment;
  onChange?: (date: Moment, closeOnSelection: boolean) => void;
  pickerType?: 'all' | 'past' | 'future';
  showTimePicker?: boolean;
}

export default function DatePicker({value, pickerType, showTimePicker, onChange}: DatePickerProps) {

  const slots = [
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
  ];

  const generateFirstSlotMoment = () => {
    let t: string = moment().format(timeFormat);
    let slot = slots.find((value) => value >= t);

    if (!slot) {
      return combineDateTime(moment().add(1, 'd'), slots[0]);
    }

    return combineDateTime(moment(), slot);
  }

  const firstAvailableSlot = generateFirstSlotMoment();

  let initialValue = value ? value : (showTimePicker && pickerType === 'future' ? firstAvailableSlot : moment());
  let [selectedDate, setSelectedDate] = React.useState<Moment>(initialValue);

  const handleChange = (newValue: Moment | null, closeOnSelect: boolean = false) => {
    if (newValue) {
      setSelectedDate(newValue);
      if (onChange) onChange(newValue, closeOnSelect);
    }
  }


  let morningSlots = () => slots.filter((item) => item <= '12:00').map((item) =>
    <TimeSlot slot={item} key={item} selected={item === getSlot(selectedDate)}
              onClickHandler={(value: any) => handleChange(combineDateTime(selectedDate, item), true)}/>
  );

  let daySlots = () => slots.filter((item) => item > '12:00' && item < '18:00').map((item) =>
    <TimeSlot slot={item} key={item} selected={item === getSlot(selectedDate)}
              onClickHandler={(value: any) => handleChange(combineDateTime(selectedDate, item), true)}/>
  );

  let eveningSlots = () => slots.filter((item) => item >= '18:00').map((item) =>
    <TimeSlot slot={item} key={item} selected={item === getSlot(selectedDate)}
              onClickHandler={(value: any) => handleChange(combineDateTime(selectedDate, item), true)}/>
  );

  let pickerStyle = showTimePicker ? {display: 'grid', gridTemplateColumns: '366px 322px'} : {display: 'block'}

  const shouldDisableDate = (date: any): boolean =>
    pickerType === 'future' && !!showTimePicker && (firstAvailableSlot.format(dateFormat) > date.format(dateFormat));

  return (
    <div style={pickerStyle}>
      <div>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="nl">
          <DateCalendar
            dayOfWeekFormatter={(day) => day.substring(0, 2)}
            value={selectedDate} onChange={(moment) => handleChange(moment)}
            disablePast={pickerType === 'future'}
            disableFuture={pickerType === 'past'}
            shouldDisableDate={shouldDisableDate}
            disableHighlightToday

          />
        </LocalizationProvider>
      </div>

      {showTimePicker &&
        <div>
          <div style={{
            display: 'flex', justifyContent: 'center', marginTop: '16px', marginBottom: '8px', paddingLeft: '24px',
            paddingRight: '12px', maxHeight: '30px', minHeight: '30px', alignItems: 'center'
          }}>
            {selectedDate.format('DD MMMM YYYY')}
          </div>
          <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr'}}>
            <div style={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'center'}}>
              <div style={{display: 'flex', height: '42px', alignItems: 'center', fontSize: 13}}>Morning</div>
              {morningSlots()}
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'center'}}>
              <div style={{display: 'flex', height: '42px', alignItems: 'center', fontSize: 13}}>Afternoon</div>
              {daySlots()}
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'center'}}>
              <div style={{display: 'flex', height: '42px', alignItems: 'center', fontSize: 13}}>Evening</div>
              {eveningSlots()}
            </div>
          </div>
        </div>
      }
    </div>
  );
}