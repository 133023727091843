import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {partyPageConnector, PartyPageProps} from './partyPageSlice';
import styles from './PartyPage.module.css';
import NoMatch from '../404/404';
import Rating from '../../components/rating/Rating';
import ImageGallery from '../../components/imageGallery/ImageGallery';
import PartyTag from '../../components/tag/partyTag/PartyTag';
import BookingWidget from '../../features/booking/bookingWidget/BookingWidget';
import {ReactComponent as ArrowBeforeIcon} from '../../icons/arrow-before.svg';
import Carousel from "../../components/carousel/Carousel";
import PartyDTO from "../../types/PartyDTO";
import parse from 'html-react-parser'


const PartyPage = (state: PartyPageProps) => {
  const {partyId} = useParams();
  const navigate = useNavigate();
  const loadParty = state.loadParty;
  const brRte = document.querySelectorAll('p > br');

  if (brRte.length) {
    brRte.forEach(br => {
      const parentBr = br.closest('p');
      if (parentBr) {
        parentBr.style.lineHeight = '0';
      }
    })
  }

  React.useEffect(() => {
    // console.log('Party load called: ', partyId);
    if (partyId)
      loadParty(+partyId);
  }, [partyId, loadParty])
  if (!partyId)
    return <NoMatch/>;

  if (!state.party)
    return (<div>{state.status}</div>)
  return (
    <main>
      <div className="container container--tiny">
        <section>
          <div className={styles._vendorBreadcrumbs}>
            <button className={styles._vendorBreadcrumbs_button} onClick={() => navigate(-1)}>
              <ArrowBeforeIcon/>
            </button>
            <h1 className={styles._vendorTitle}>{state.party.title}</h1>
          </div>
          <div className={styles._vendorHeading}>
            <div className={styles._vendorLocationWrapper}>
              <Rating rating={state.party.rating} review={0} tooltipPlace={'bottom-start'}/>
              { state.party.location &&
                  <p className={styles._vendorLocation}>{state.party.location}</p>
              }
            </div>
            <div className={styles._vendorHeading_actions}>

            </div>
          </div>
          <div className={styles._vendorImages} style={!state.party.photos?.length ? {aspectRatio: '1120/362'} : {}}>
            <ImageGallery
              title={state.party.title}
              pictures={state.party.photos?.map(photo => photo.url) ?? []}
            />
          </div>
          <div className={styles._party_container}>
            <div className={styles._party_details}>
              <div className={styles._party_details_heading}>
                <h2 className={styles._party_title}>{state.party.vendor!.title}</h2>
                {(state.party.tagList?.length ?? 0) > 0 && <PartyTag tags={state.party.tagList!}/>}
              </div>
              <div className={styles._rte}>
                {parse(state.party.description + '')}
              </div>
              {state.party.extraInformation &&
                  <div className={styles._rte}>
                    {parse(state.party.extraInformation + '')}
                  </div>
              }
            </div>
            <BookingWidget party={state.party}/>
          </div>
        </section>
        {(state.party.vendorParties?.length ?? 0) > 1 &&
          <section className={'section section--border-bottom'}>
            <h2 className={'section__title'}>{"More by " + state.party!.vendor!.title}</h2>
            <Carousel items={state.party.vendorParties?.filter((value: PartyDTO) => value.id !== state.party?.id)}/>
          </section>}

      </div>
    </main>
  );
}

export default partyPageConnector(PartyPage);
