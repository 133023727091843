import RenderTerms from "../../components/renderTerms/RenderTerms";
import terms from "./TermsEN.json"

const VendorCancellationPolicy = () => {
    const data = terms.vendorCancellationPolicy;

    return (
        <RenderTerms pageTitle={data.pageTitle} caption={data.caption} main={data.main as []}/>
    )
}

export default VendorCancellationPolicy;