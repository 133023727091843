import React from "react";
import styles from './Footer.module.css';
import FooterLinks from './footerLinks/FooterLinks';
import LanguageSwitcher from "../languageSwitcher/LanguageSwitcher";
import {useTranslation} from "react-i18next";
import {COMPANY_REGISTRATION_ROUTE, TERMS_AND_CONDITIONS_ROUTE} from "../../app/Routes";
import {useShowModalMessage} from "../modal/ModalWindowContext";

type FooterType = {
  version?: string
}

const Footer = ({version}: FooterType) => {
  const {t} = useTranslation();
  const showMessage = useShowModalMessage();

  const footerLinks = [
    {
      text: t('Terms & Conditions'),
      href: TERMS_AND_CONDITIONS_ROUTE,
    },
    {
      text: t('Register company'),
      href: COMPANY_REGISTRATION_ROUTE,
    },
    {
      text: t('Contact Us'),
      action: () =>  showMessage.form({content: 'contactus', title: t('Contact Us')}),
    }
  ]

  return (
    <footer className={styles._main}>
      <div className="container">
        <div className={styles._wrapper}>
          <div className={styles._content}>
            <div className={styles._wrapper}>
              <p className={styles._copyright}><span>&#169;</span> 2024 Kidsvenu
                {version &&
                  <span className={styles._version}>version {version}</span>
                }
              </p>
            </div>
            <div className={styles._links}>
              <FooterLinks links={footerLinks as []}/>
            </div>
          </div>
          <LanguageSwitcher/>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
