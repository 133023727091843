import React from 'react';
import PartyOptionDTO from "../types/PartyOptionDTO";
import styles from './Input.module.css';
import {RadioButtonInput} from "./Input";

type PartyOptionsProps = {
  title: string;
  partyOptions: PartyOptionDTO[];
  selectedOption?: PartyOptionDTO;
  setSelectedOption: (partyOption: PartyOptionDTO) => void;
}

const PartyOptions = ({title, partyOptions, selectedOption, setSelectedOption}: PartyOptionsProps) => {
  function optionOrder(a: PartyOptionDTO, b: PartyOptionDTO) {
    return a.order - b.order;
  }

  const formatPrice = (item: PartyOptionDTO) =>
    '€' + item.price + (item.priceType === 'pp' ? ' p.p.' : ' v.a.')

  return (
    <div className={styles.inputBlock}>
      <div className={styles.partyOptions}>
        <p className={styles.partyOptionsTitle}>{title}</p>
        {
          [...partyOptions].sort(optionOrder).map((item: PartyOptionDTO) =>
            <RadioButtonInput
              title={item.title + " - " + formatPrice(item)}
              description={item.description}
              name="bookingOption"
              key={item.id}
              checked={selectedOption?.id === item.id}
              onChange={() => setSelectedOption(item)}/>
          )
        }
      </div>
    </div>
  )
}

export default PartyOptions;