import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../app/store";
import PartyDTO from "../../types/PartyDTO";
import API from "../../app/api";
import {sortPhoto} from "../../utils/sort_utils";

export interface PartyPageState {
  party?: PartyDTO;
  status: 'idle' | 'loading' | 'failed';
}

export const initialState: PartyPageState = {
  party: undefined,
  status: 'idle',
};

const loadParty = createAsyncThunk(
  'vendor/loadParty',
  async (partyId: number) => {
    return await API.loadParty(partyId);
  }
);

export const partyPageSlice = createSlice({
  name: 'party',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder

      .addCase(loadParty.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadParty.fulfilled, (state, action) => {
        state.status = 'idle';
        let party = action.payload as PartyDTO;
        state.party = party;
        if(party != null && party.photos != null && party.photos.length > 1){
          party.photos = party.photos.sort(sortPhoto('id'))
        }
      })
      .addCase(loadParty.rejected, (state) => {
        state.status = 'failed';
      })
  },
});


export const actionsRef = {
  loadParty: loadParty,
}

export const stateRef = (state: RootState) => ({ party: state.party.party, status: state.party.status });

export const partyPageConnector = connect(stateRef, actionsRef)
export type PartyPageProps = ConnectedProps<typeof partyPageConnector>;

export default partyPageSlice.reducer;
