export class CompanyRegistrationDTO {
  companyName: string = "";
  description: string = "";
  userName: string = "";
  phone: string = "";
  address: string = "";
  email: string = "";
  kvkNumber: string = "";
  password: string = "";
  iban: string = "";
  agreement: boolean = false;
  country: boolean = false;
}

export class ValidationMessage {
  companyName?: string;
  description?: string;
  userName?: string;
  phone?: string;
  address?: string;
  email?: string;
  kvkNumber?: string;
  password?: string;
  password2?: string;
  iban?: string;
  agreement?: string;
  country?: string;
}