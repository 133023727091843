import styles from './PartyTag.module.css';
import { Tooltip } from "react-tooltip";
import { useTranslation } from 'react-i18next';
import 'react-tooltip/dist/react-tooltip.css';
import TagDTO from "../../../types/TagDTO";

type PartyTagsProps = {
    tags: TagDTO[];
}



const PartyTagItem = (tag: TagDTO) => {

    const {t} = useTranslation();

    // @ts-ignore
    return  <li className={styles._item} key={tag.id} data-tooltip-id={tag.id}>
          <span>#</span>
          <span>{t(tag.name)}</span>
          <Tooltip id={'' + tag.id} place={"top"}>
              {t(tag.description)}
          </Tooltip>
      </li>


}

const PartyTag = ({tags}: PartyTagsProps) => {
    return (
      <ul className={styles._list}>
          {tags?.map(PartyTagItem)}
      </ul>
    )
}

export default PartyTag;