import * as url from "./service_url";
import * as service from "./service"
import BookingDTO from "../types/BookingDTO";
// import {BOOKING_CONFIRMATION} from "./service_url";


//api sous
// export const vendorListGet = async (): Promise<any> => service.getList(url.VENDOR_LIST);
// export const vendorCreate = async (vendor: object): Promise<any> => service.putItem(url.VENDOR_PUT, vendor);
// export const vendorGet = async (vendorId: number): Promise<any> => service.getSingle(url.VENDOR_INFO, vendorId);
// export const vendorUpdate = async (vendor: object): Promise<any> => service.patchItem(url.VENDOR_PATCH, vendor);
// export const vendorDelete = async (vendorId: number): Promise<any> => service.deleteItem(url.VENDOR_DELETE, vendorId);



// export const vendorPhotoListGet = async (vendorId: number): Promise<any> => service.getParentedList(url.VENDOR_PHOTO_LIST, vendorId);
// export const vendorPhotoCreate = async (vendorPhoto: object): Promise<any> => service.putItem(url.VENDOR_PHOTO_PUT, vendorPhoto);
// export const vendorPhotoGet = async (photoId: number): Promise<any> => service.getSingle(url.VENDOR_PHOTO_INFO, photoId);
// export const vendorPhotoUpdate = async (vendorPhoto: object): Promise<any> => service.patchItem(url.VENDOR_PHOTO_PATCH, vendorPhoto);
// export const vendorPhotoDelete = async (photoId: number): Promise<any> => service.deleteItem(url.VENDOR_PHOTO_DELETE, photoId);

export const domainTestGet = async (): Promise<any> => service.testDomainGet(url.DOMAIN_TEST_URL);

export const getPartyList = async (search?: string, tags?: string, page?: number, pageSize?: number): Promise<any> => service.getList(url.PARTY_LIST, search, tags, page, pageSize);

export const getParty = async (partyId: number): Promise<any> => service.getSingle(url.PARTY_INFO, partyId);

export const submitBooking = async (booking: BookingDTO): Promise<any> => service.postItem(url.BOOKING_SUBMIT, booking);

export const getBookingConfirmation = async (bookingReference: string)=> service.getSingle(url.BOOKING_CONFIRMATION, bookingReference);