import * as service from "./vendor_service";

export async function loadParty(partyId: number) {
    let response = await service.getParty(partyId);
    return response.data;
}

export async function loadParties(search?: string, tags?: string, page?: number, pageSize?: number) {
    let response = await service.getPartyList(search, tags, page, pageSize);
    return response.data;
}
