import React from "react";
import styles from './FooterLinks.module.css'
import Button, {ButtonModification} from "../../button/Button";

type FooterLinkType = {
    link: {
        href?: string;
        text?: string;
        action?: Function;
    }
}

const FooterLink = ({link}: FooterLinkType) => {
    return (
        <Button href={link.href} text={link.text} action={link.action} modification={ButtonModification.link} />
    )
}

type FooterLinksType = {
    links?: [],
    listClassName?: string,
    itemClassName?: string,
}

const FooterLinks = ({links, listClassName, itemClassName }: FooterLinksType) => {
    return (
        <ul className={listClassName ? (styles._list + ' ' + listClassName) : styles._list}>
            {links && links.map((link, index) =>
                <li
                    key={index}
                    className={itemClassName ?
                        styles._item + ' ' + itemClassName
                        : styles._item
                    }
                >
                    <FooterLink link={link} />
                </li>
            )}
        </ul>
    )
}

export default FooterLinks;
