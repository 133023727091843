import RenderTerms from "../../components/renderTerms/RenderTerms";
import terms from "./TermsEN.json"

const CommunityDisturbancePolicy = () => {
    const data = terms.communityDisturbancePolicy;

    return (
        <RenderTerms pageTitle={data.pageTitle} caption={data.caption} main={data.main as []}/>
    )
}

export default CommunityDisturbancePolicy;