export class UserRegistrationDTO {
  name: string = "";
  email: string = "";

  constructor(name: string = "", email: string = ""){
    this.name = name;
    this.email = email;
  }

  copyWithName = (name: string ): UserRegistrationDTO => new UserRegistrationDTO(name, this.email);
  copyWithEmail = (email: string ): UserRegistrationDTO => new UserRegistrationDTO(this.name, email);

}