export const WEB_URL: string = process.env.REACT_APP_KIDSVENU_WEB || "http://localhost:3000";
export const VENDOR_URL : string = process.env.REACT_APP_KIDSVENU_VENDOR || "https://vendor.kidsvenu.nl";

export const PARTY_ROUTE: string = "/party/:partyId";
export const PARTY_BOOK_ROUTE: string = "/party/:partyId/book";
export const PARTY_BOOK_DONE_ROUTE: string = "/party/:partyId/book/done";
export const SEARCH_ROUTE: string = "/search";
export const USER_REGISTRATION_ROUTE: string = "/user_registration";
export const COMPANY_REGISTRATION_ROUTE: string = "/company_registration";
export const ACTIVATION_ROUTE: string = "/activation/:activationCode";
export const BOOKING_CONFIRMATION_ROUTE: string = "/bookingConfirmation/:bookingId";
export const BOOKING_CONFIRMATION_DONE_ROUTE: string = "/bookingConfirmation/done";
export const TERMS_AND_CONDITIONS_ROUTE: string = "/terms_and_conditions";
export const EXTENUATING_CIRCUMSTANCES_POLICY_ROUTE: string = "/extenuating_circumstances_policy";
export const REBOOKING_AND_REFUND_POLICY_ROUTE: string = "/rebooking_and_refund_policy";
export const VENDOR_CANCELLATION_POLICY_ROUTE: string = "/vendor_cancellation_policy";
export const COMMUNITY_DISTURBANCE_POLICY_ROUTE: string = "/community_disturbance_policy";
export const KIDSVENU_CONTENT_POLICY_ROUTE: string = "/kidsvenu_content_policy";
export const KIDSVENU_REVIEWS_POLICY_ROUTE: string = "/kidsvenu_reviews_policy";
export const NONDISCRIMINATION_POLICY_ROUTE: string = "/nondiscrimination_policy";
export const PRIVACY_POLICY_ROUTE: string = "/privacy_policy";
export const OUR_COMMUNITY_STANDARDS_ROUTE: string = "/our_community_standards";


export const getFullUrl = (subUrl: string) =>
  WEB_URL + subUrl;

export const GET_PARTY_URL = (partyId: string | number, full: boolean = false) => (full ? WEB_URL : "") + PARTY_ROUTE.replace(":partyId", "" + partyId);
export const GET_PARTY_BOOK_URL = (partyId: string | number, full: boolean = false) => (full ? WEB_URL : "") + PARTY_BOOK_ROUTE.replace(":partyId", "" + partyId);
export const GET_PARTY_BOOK_DONE_URL = (partyId: string | number, full: boolean = false) => (full ? WEB_URL : "") + PARTY_BOOK_DONE_ROUTE.replace(":partyId", "" + partyId);
export const GET_SEARCH_URL = (full: boolean = false) => (full ? WEB_URL : "") + SEARCH_ROUTE;
export const GET_BOOKING_CONFIRMATION_URL = (bookingId: string | number, full: boolean = false) => (full ? WEB_URL : "") + BOOKING_CONFIRMATION_ROUTE.replace(":bookingId", "" + bookingId);
export const GET_BOOKING_CONFIRMATION_DONE_URL = (full: boolean = false) => (full ? WEB_URL : "") + BOOKING_CONFIRMATION_DONE_ROUTE;