import styles from './LanguageSwitcher.module.css'
import { ReactComponent as Global } from '../../icons/global.svg';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {

    const { i18n } = useTranslation();
    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };
    // const [isOpen, setOpen] = useState(false);

    return (
        <>
            <label
                className={styles.label}
                aria-label={i18n.language === 'en'
                    ? 'selected language English'
                    : i18n.language === 'nl'
                        ? 'selected language Dutch'
                        : 'selected language French'}
            >
                <Global />
                <select
                    className={styles.select}
                    value={i18n.language}
                    onChange={(event) => changeLanguage(event.target.value)}
                >
                    <option value="en">English</option>
                    <option value="nl">Nederlands</option>
                </select>
            </label>
        </>
    )
}

export default LanguageSwitcher;
