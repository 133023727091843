import {t} from 'i18next';

export const validateString = (
  value: string | undefined | null,
  message: string,
  isRequired: boolean
): [string | undefined, boolean] => {
  if (isRequired && !value) {
    return [message, false]
  }

  return [undefined, true]
}

export const validateBoolean = (
  value: boolean | undefined | null,
  isRequired: boolean
): [true | undefined, boolean] => {
  if (isRequired && !value) {
    return [true, false]
  }

  return [undefined, true]
}

export const validateDateOfParty = (
  dateOfParty?: string
): [string | undefined, boolean] =>
  validateString(dateOfParty, t('Date of the party is required'), true)

export const validateNumberOfKids = (
  numberOfKids: number,
  minKids: number,
  maxKids: number
): [string | undefined, boolean] => {
  if (numberOfKids < 1) {
    return [t('Number of kids can be not less than 1'), false]
  } else if (minKids !== 0 && minKids > numberOfKids) {
    return [t('Min allowed amount of kids is {{minKids}}', {minKids : minKids}  ), false]
  } else if (maxKids !== 0 && maxKids < numberOfKids) {
    return [t('Max allowed amount of kids is {{maxKids}}', {maxKids: maxKids}), false]
  }

  return [undefined, true]
}

export const validateFullName = (
  fullName?: string
): [string | undefined, boolean] =>
  validateString(fullName, t('Contact name is required'), true)

export const validateAgreement = (
  agreement?: boolean
): [true | undefined, boolean] => validateBoolean(agreement, true)

export const validateEmail = (
  email?: string
): [string | undefined, boolean] => {
  if (!email) {
    return [t('Email is required'), false]
  }

  const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  if (!expression.test(email)) {
    return [t('Email is invalid'), false]
  }

  return [undefined, true]
}

export const validateAddress = (
  address?: string
): [string | undefined, boolean] =>
  validateString(address, t('Address is required'), true)
