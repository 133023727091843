import styles from './Input.module.css';
import classNames from "classnames";
import React from "react";
import {Trans} from 'react-i18next';

type InputType = 'number' | 'text' | "password" | "checkbox" | "radio" | "textarea";
type InputWrapProps = {
    name: string;
    inputType: InputType;
    lastField?: boolean;
    description?: string;
    descriptionKey?: string;
    errorMessage?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}

type DescriptionProps = {
    description?: string;
    descriptionKey?: string;
    className: string;
}

type ErrorProps = {
    errorMessage?: string;
}

type InputProps = {
    title: string;
    name: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    lastField?: boolean;

    key?: string | number | boolean;

    readonly?: boolean;

    description?: string;
    descriptionKey?: string;
    errorMessage?: string;
}

type numberChangeHandler = (newValue: number, name: string) => void;
type textChangeHandler = (newValue: string, name: string) => void;
type checkBoxChangeHandler = (newValue: boolean, name: string) => void;

type NumberInputProps = {
    value?: number | string;
    onChange?: numberChangeHandler;//(newValue: number, name: string) => void;
    min?: number;
    max?: number;
} & InputProps

type TextInputProps = {
    placeholder?: string;
    value?: string;
    onChange?: textChangeHandler;
} & InputProps

type CheckBoxInputProps = {
    value?: boolean;
    onChange?: checkBoxChangeHandler;
} & InputProps

type RadioButtonInputProps = {
    value?: boolean;
    checked?: boolean;
    onChange?: checkBoxChangeHandler;
} & InputProps

const getChangeHandler = (
    inputType: InputType,
    onChange?: numberChangeHandler | textChangeHandler | checkBoxChangeHandler
) => (event: React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!onChange) {
        return;
    }
    switch (inputType) {
        case 'number':
            (onChange as numberChangeHandler)(+event.target.value, event.target.name);
            break;
        case 'text':
        case 'textarea':
        case 'password':
            (onChange as textChangeHandler)(event.target.value, event.target.name);
            break;
        case 'checkbox':
        case 'radio':
            (onChange as checkBoxChangeHandler)(event.target.checked, event.target.name);
            break;
        default:
            throw new Error('UNKNOWN INPUT TYPE: ' + inputType);
    }
}

const iphone = /iPad|iPhone/.test(navigator.userAgent);

const InputDescription = ({description, descriptionKey, className}: DescriptionProps) => {
    return (
        <>
            {description && <span className={className}>{description}</span>}
            {descriptionKey && <span className={className}><Trans i18nKey={descriptionKey}/></span>}
        </>
    )
}

const InputError = ({errorMessage}: ErrorProps) => {
    return (
        <>
            {errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
        </>
    )
}

const InputWrapper = ({
                          name,
                          onClick,
                          lastField,
                          inputType,
                          children,
                          description,
                          descriptionKey,
                          errorMessage
                      }: InputWrapProps & any) => {
    return (
        <div className={name === "country" ? styles.inputBlockCountry : styles.inputBlock} onClick={onClick}
             style={lastField ? {} : {borderBottom: 0}}>
            <label className={inputType === "checkbox"
                ? styles._checkbox_label
                : inputType === "radio"
                    ? styles._radio_label
                    : styles.input}>

                {children}

                <span className={styles._description_wrapper}>
                    <InputDescription description={description}
                          descriptionKey={descriptionKey}
                          className={styles._description}/>
                    <InputError errorMessage={errorMessage}/>
                </span>
            </label>
        </div>
    );
}

export const NumberInput = ({
                                title, name,
                                onChange, onClick,
                                readonly, lastField,
                                value, min, max,
                                description, descriptionKey,
                                errorMessage,
                            }: NumberInputProps) => {
    const inputType = 'number';
    const internalOnChange = getChangeHandler(inputType, onChange);

    return (
        <InputWrapper inputType={inputType}
                      onClick={onClick}
                      lastField={lastField}
                      name={name}
                      description={description}
                      descriptionKey={descriptionKey}
                      errorMessage={errorMessage}>
            <span className={styles.inputLabel}>{title}</span>
            <input className={classNames(styles.inputField, (!!errorMessage ? styles.error : ``),)}
                   type="number"
                   name={name}
                   value={value}
                   onChange={internalOnChange}
                   readOnly={readonly}
                   min={min}
                   max={max}/>

        </InputWrapper>
    )
}

export const TextInput = ({
                              title,
                              description,
                              descriptionKey,
                              placeholder,
                              name,
                              value,
                              onChange,
                              onClick,
                              readonly,
                              lastField,
                              errorMessage
                          }: TextInputProps) => {
    const inputType = "text";
    const internalOnChange = getChangeHandler(inputType, onChange);

    return (
        <InputWrapper inputType={inputType}
                      onClick={onClick}
                      lastField={lastField}
                      name={name}
                      description={description}
                      descriptionKey={descriptionKey}
                      errorMessage={errorMessage}>

            <span className={styles.inputLabel}>{title}</span>

            <input className={classNames(styles.inputField, (!!errorMessage ? styles.error : ``),)}
                   type={inputType}
                   placeholder={placeholder}
                   name={name}
                   value={value}
                   onChange={internalOnChange}
                   readOnly={readonly}/>
        </InputWrapper>
    )
}

export const PasswordInput = ({
                                  title,
                                  description,
                                  descriptionKey,
                                  placeholder,
                                  name,
                                  value,
                                  onChange,
                                  onClick,
                                  readonly,
                                  lastField,
                                  errorMessage
                              }: TextInputProps) => {
    const inputType = "password";
    const internalOnChange = getChangeHandler(inputType, onChange);

    return (
        <InputWrapper
            inputType={inputType}
            onClick={onClick}
            lastField={lastField}
            name={name}
            description={description}
            descriptionKey={descriptionKey}
            errorMessage={errorMessage}>

            <span className={styles.inputLabel}>{title}</span>

            <input className={classNames(styles.inputField, (!!errorMessage ? styles.error : ``),)}
                   type={inputType}
                   placeholder={placeholder}
                   name={name}
                   value={value}
                   onChange={internalOnChange}
                   readOnly={readonly}/>
        </InputWrapper>
    )
}

export const TextAreaInput = ({
                                  title,
                                  description,
                                  descriptionKey,
                                  placeholder,
                                  name,
                                  value,
                                  onChange,
                                  onClick,
                                  readonly,
                                  lastField,
                                  errorMessage
                              }: TextInputProps) => {
    const inputType = "textarea";
    const internalOnChange = getChangeHandler(inputType, onChange);

    return (
        <InputWrapper
            inputType={inputType}
            onClick={onClick}
            lastField={lastField}
            name={name}
            description={description}
            descriptionKey={descriptionKey}
            errorMessage={errorMessage}>

            <span className={styles.inputLabel}>{title}</span>

            <div className={styles._textarea_wrapper}>
        <textarea className={styles._textarea}
                  readOnly={readonly}
                  onChange={internalOnChange}
                  name={name}
                  value={value}
                  placeholder={placeholder}/>
            </div>
        </InputWrapper>
    )
}

export const CheckBoxInput = ({
                                  title,
                                  description,
                                  descriptionKey,
                                  name,
                                  value,
                                  onChange,
                                  onClick,
                                  readonly,
                                  lastField,
                                  errorMessage,
                              }: CheckBoxInputProps) => {
    const inputType = 'checkbox';
    const internalOnChange = getChangeHandler(inputType, onChange);

    return (
        <InputWrapper
            inputType={inputType}
            onClick={onClick}
            lastField={lastField}
            name={name}
            description={description}
            descriptionKey={descriptionKey}
            errorMessage={errorMessage}>

            <input
                className={classNames(
                    styles._checkbox_input,
                    (!!errorMessage ? styles.error : ``),
                )}
                type="checkbox"
                name={name}
                checked={value as boolean}
                onChange={internalOnChange}
                readOnly={readonly}/>

            <span className={styles._checkbox_wrapper}>
                {iphone ?
                    <span className={classNames(styles._checkbox_pseudo_iphone)}>
                        <span className={classNames(styles._checkbox_pseudo_iphone__circle)}></span>
                    </span>
                    : <span className={classNames(styles._checkbox_pseudo)}>&#10004;</span>
                }

                <span className={classNames(styles._checkbox_text, !!errorMessage ? styles.error : ``)}>{title}</span>
            </span>
        </InputWrapper>
    )
}

export const RadioButtonInput = ({
                                     title,
                                     description,
                                     descriptionKey,
                                     name,
                                     value,
                                     onChange,
                                     onClick,
                                     readonly,
                                     lastField,
                                     errorMessage,
                                     checked,
                                 }: RadioButtonInputProps) => {
    const inputType = 'radio';
    const internalOnChange = getChangeHandler(inputType, onChange);

    return (
        <InputWrapper
            inputType={inputType}
            onClick={onClick}
            lastField={lastField}
            description={description}
            descriptionKey={descriptionKey}
            name={name}
            errorMessage={errorMessage}>

             <span className={styles._radio_wrapper}>
                 <input className={classNames(styles.inputField, (!!errorMessage ? styles.error : ``),)}
                   type={inputType} name={name}
                   checked={checked}
                   value={typeof value == "boolean" ? '' : value}
                   onChange={internalOnChange} readOnly={readonly}/>

                <span className={styles._radio_text_wrapper}>
                    <span className={styles._radio_title}>{title}</span>
                </span>
             </span>


        </InputWrapper>
)
}