import RenderTerms from "../../components/renderTerms/RenderTerms";
import terms from "./TermsEN.json"

const RebookingAndRefundPolicy = () => {
    const data = terms.rebookingAndRefundPolicy;

    return (
        <RenderTerms pageTitle={data.pageTitle} main={data.main as []} />
    )
}

export default RebookingAndRefundPolicy;