import {IMAGE_URL} from "../service/service_url";

export class ImageSize {
  static XXL= new ImageSize("2240x1120");
  static XL = new ImageSize("1120x1120");
  static LG = new ImageSize("1120x560");
  static MD = new ImageSize("560x560");
  static SM = new ImageSize("560x280");
  static XS = new ImageSize("280x280");

  private readonly value: string;
  constructor(value: string) {
    this.value = value;
  }

  public toString(){
    return this.value;
  }
}

export  class ImageFormat {
  static JPG = new ImageFormat("jpg");
  static PNG = new ImageFormat("png");
  static WEBP = new ImageFormat("webp");

  private readonly value: string;
  constructor(value: string) {
    this.value = value;
  }

  public toString(){
    return this.value;
  }
}

export const getImageUrl = (source: string | null | undefined, size: ImageSize, format: ImageFormat): string => {
  if (!source) {
    return "";
  }

  return IMAGE_URL
    .replace('{size}', size.toString())
    .replace('{image}', source)
    .replace('{format}', format.toString());
}