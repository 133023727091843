// import BookingDTO from "../types/BookingDTO";
// import BookingConfirmation from "../types/BookingConfirmation";
// import {PartyDTO} from "../types/PartyDTO";
// import data from "./dataMock";
// import {getBookingConfirmation as getBookingConfirmationMock} from './bookingApi';

import * as PartyApi from "../service/partyAPI";
import * as BookingApi from "../service/BookingAPI";
import * as TagsApi from "../service/TagsAPI";
// import {BookingExtraOptionDTO} from "../types/PartyExtraOptionDTO";
// import {getConfirmation} from "../service/BookingAPI";

// const apiUrl = process.env.REACT_APP_KIDSVENU_API || "http://localhost:8080";
// const useMocks = process.env.REACT_APP_KIDSVENU_MOCK === 'true' ? true : false;

// console.log(JSON.stringify(process.env));


const API =
  {
    loadParties: PartyApi.loadParties,
    loadParty: PartyApi.loadParty,
    submitBooking: BookingApi.submit,
    getBookingConfirmation: BookingApi.getConfirmation,
    getTagsList: TagsApi.loadTags,
  }

export default API;