import React from "react";
import styles from './Header.module.css'
import SearchForm from "../searchForm/SearchForm";
import { Link, useLocation } from "react-router-dom";
import { VENDOR_URL } from "../../app/Routes";
import { useTranslation } from "react-i18next";
// import ProfileMenu from "./profileMenu/ProfileMenu";

const Header = () => {

    const { t } = useTranslation();
    let location = useLocation();

    return (
        <header className={styles._main}>
            <div className="container">
                <div className={styles._content}>
                    <div className={styles._logo}>
                        {location.pathname === '/' ?
                            <picture>
                                <source media="(max-width: 460px)" srcSet="/logo_short.svg" width="32" height="40" />
                                <img src="/logo.svg" alt="logo" height="24" width="132" />
                            </picture>
                            :
                            <a href='/'>
                                <picture>
                                    <source media="(max-width: 460px)" srcSet="/logo_short.svg" width="32" height="40" />
                                    <img src="/logo.svg" alt="logo" height="24" width="132" />
                                </picture>
                            </a>
                        }
                    </div>
                    <div className={styles._search}>
                        {/*<SearchWidget />*/}
                        <SearchForm />
                    </div>
                    <div className={styles._cta}>
                        <Link to={VENDOR_URL}>{t('Vendor login')}</Link>
                        {/* <ProfileMenu /> */}
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;
