import React, {MouseEventHandler} from 'react';
import Fancybox from "../fancybox/Fancybox";
import styles from './Image.module.css';

type ImageProps = {
    className?: string;
    classNameImg?: string;
    imageSource?: string;
    imageSource2x?: string;
    imageSourceWebp?: string;
    imageSourceWebp2x?: string;
    imageMobile?: string;
    imageMobile2x?: string;
    imageMobileWebp?: string;
    imageMobileWebp2x?: string;
    imageFancy?: string;
    width?: number;
    height?: number;
    alt?: string;
    useFancy?: string;
    onlyFancy?: boolean;
    onClick?: MouseEventHandler;
}

const Image = ({
                   className,
                   classNameImg,
                   imageSource,
                   imageSource2x,
                   imageSourceWebp,
                   imageSourceWebp2x,
                   imageMobile,
                   imageMobile2x,
                   imageMobileWebp,
                   imageMobileWebp2x,
                   imageFancy,
                   width,
                   height,
                   alt,
                   useFancy,
                   onlyFancy,
                   onClick
}: ImageProps) => {

    return (
        useFancy?.length ?
            <Fancybox
                dataFancybox={useFancy}
                href={imageFancy}
                options={{
                    Toolbar: {
                        display: {
                            left: [],
                            middle: [],
                            right: ["slideshow", "thumbs", "close"],
                        }
                    },
                    Carousel: {
                        infinite: true,
                    }
                }}
            >
                {!onlyFancy ?
                    <picture
                        onClick={onClick}
                        className={className ? styles._picture + ' ' + className : styles._picture}
                    >
                        {imageMobileWebp && imageMobileWebp2x
                            ? <source media={'(max-width: 540px)'} type={'image/webp'} srcSet={ imageMobileWebp + ', ' + imageMobileWebp2x + ' 2x'} /> : ''}
                        {imageMobile && imageMobile2x
                            ? <source media={'(max-width: 540px)'} type={'image/jpg'} srcSet={ imageMobile + ', ' + imageMobile2x + ' 2x'} /> : ''}
                        {imageSourceWebp && imageSourceWebp2x
                            ? <source type={'image/webp'} srcSet={ imageSourceWebp + ', ' + imageSourceWebp2x + ' 2x'}/> : ''}
                        {imageSource && imageSource2x
                            ? <source type={'image/jpg'} srcSet={ imageSource + ', ' + imageSource2x + ' 2x'}/>  : ''}
                        <img
                            src={imageSource}
                            className={classNameImg ? styles._image + ' ' + classNameImg : styles._image}
                            alt={alt}
                            width={width}
                            height={height}
                        />
                    </picture>
                : ''}
            </Fancybox>
            :
            <picture
                onClick={onClick}
                className={className ? styles._picture + ' ' + className : styles._picture}
            >
                {imageSourceWebp && imageSourceWebp2x
                    ? <source type={'image/webp'} srcSet={imageSourceWebp + ', ' + imageSourceWebp2x + ' 2x'}/> :''}
                {imageSource && imageSource2x
                    ? <source type={'image/jpg'} srcSet={imageSource + ', ' + imageSource2x + ' 2x'}/> : ''}
                <img
                    src={imageSource}
                    className={classNameImg ? styles._image + ' ' + classNameImg : styles._image}
                    alt={alt}
                    width={width}
                    height={height}
                />
            </picture>
    )
}

export default Image;

