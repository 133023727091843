import { Link } from "react-router-dom";
import styles from './404.module.css';
import { useTranslation } from 'react-i18next';

export const NoMatch = () => {

    const { t } = useTranslation();

    return (
        <main>
            <div className={styles._wrapper}>
                <div className={styles._content}>
                    <h1 className={styles._title}>{t('Nothing to see here!')}<span>404</span></h1>
                    <Link className={styles._action} to="/">{t('Go to the home page')}</Link>
                </div>
            </div>
        </main>
    );
}

export const Loading = () => {
    return (
        <div>
            <h2>Loading data</h2>
            <p>
            Please, wait!
      </p>
    </div>
  );
}

export default NoMatch;