export const filterUnique = (value: any, index: number, array: any[]) => array.indexOf(value) === index;

const paramArrayDelimiter = ',';
export const arrayToParamString = (array: any[]) => array.join(paramArrayDelimiter);
export const paramStringToArray = (param: string): any[] => param.split(paramArrayDelimiter);

export const paramStringToNumberArray = (param: string): number[] => !param.length ? [] : paramStringToArray(param).map((a: any) => parseInt(a));


export const arraysAreEqual = (a: number[], b: number[]) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}