import RenderTerms from "../../components/renderTerms/RenderTerms";
import terms from "./TermsEN.json"

const KidsvenuReviewsPolicy = () => {
    const data = terms.kidsvenuReviewsPolicy;

    return (
        <RenderTerms pageTitle={data.pageTitle} caption={data.caption} main={data.main as []}/>
    )
}

export default KidsvenuReviewsPolicy;