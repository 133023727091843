import styles from './ImageOverlay.module.css';
import Image from '../image/Image';

const imageDefault = {
    src: '/placeholder.svg',
    src2x: '',
    srcWebp: '',
    srcWebp2x: '',
    width: 340,
    height: 336,
    alt: 'DEFAULT_IMAGE_ALT'
}

const ImageOverlay = ({
                          src = imageDefault.src,
                          src2x = imageDefault.src2x,
                          srcWebp = imageDefault.srcWebp,
                          srcWebp2x = imageDefault.srcWebp2x,
                          width = imageDefault.width,
                          height = imageDefault.height,
                          alt = imageDefault.alt
                      }) => {

    return (
        <div className={styles._wrapper}>
            <img className={styles._overlay} src={src} alt={alt + ' overlay'} width={width} height={height}/>
            <Image
                imageSource={src}
                imageSource2x={src2x}
                imageSourceWebp={srcWebp}
                imageSourceWebp2x={srcWebp2x}
                width={width}
                height={height}
                alt={alt}
            />
        </div>
    )
}

export default ImageOverlay;
