const API_PREFIX: string = '/api';
const API: string = `${API_PREFIX}/site`;

export const DOMAIN_TEST_URL: string = `${API_PREFIX}/test/domain`;

export const IMAGE_URL: string = `${API_PREFIX}/img/{size}/{image}.{format}`;

// ============   VENDOR   =============
// const VENDOR_API: string = API + '/vendor';
//
// export const VENDOR_LIST: string = `${VENDOR_API}`;
// export const VENDOR_INFO: string = `${VENDOR_API}`;
// export const VENDOR_DELETE: string = `${VENDOR_API}`;
// export const VENDOR_PATCH: string = `${VENDOR_API}`;
// export const VENDOR_PUT: string = `${VENDOR_API}`;


// ============   VENDOR_PHOTO   =============
// const VENDOR_PHOTO_API: string = API + '/vendorPhoto';
//
// export const VENDOR_PHOTO_LIST: string = `${VENDOR_PHOTO_API}/list/`;
// export const VENDOR_PHOTO_INFO: string = `${VENDOR_PHOTO_API}`;
// export const VENDOR_PHOTO_DELETE: string = `${VENDOR_PHOTO_API}`;
// export const VENDOR_PHOTO_PATCH: string = `${VENDOR_PHOTO_API}`;
// export const VENDOR_PHOTO_PUT: string = `${VENDOR_PHOTO_API}`;


// ============   PARTY   =============
const PARTY_API: string = API + '/party';

export const PARTY_LIST: string = `${PARTY_API}`;
export const PARTY_INFO: string = `${PARTY_API}`;


// ============   BOOKING   =============
const BOOKING_API: string = API + '/booking';

export const BOOKING_SUBMIT: string = `${BOOKING_API}`;
export const BOOKING_CONFIRMATION: string = `${BOOKING_API}/bookingConfirmation`;

// ============   USER   =============
const USER_API: string = API + '/user';

export const USER_REGISTER: string = `${USER_API}`;
export const USER_ACTIVATE: string = `${USER_API}/activate`;
export const USER_CHECK_ACTIVATION_CODE: string = `${USER_API}`;


// ============   REGISTRATION   =============
const REGISTRATION_API = API + '/registration'

export const REGISTRATION_USER: string = `${REGISTRATION_API}/user`;
export const REGISTRATION_COMPANY: string = `${REGISTRATION_API}/company`;

// ============   MESSAGE   =============
const MESSAGE_API = API + '/message';

export const MESSAGE_CONTACT_US: string = `${MESSAGE_API}/contactUs`;

// ============   TAGS   =============
const TAGS_API = API + '/tags';

export const TAGS_LIST: string = `${TAGS_API}`;