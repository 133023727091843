import moment, {Moment} from "moment";

export const dateFormat: string = 'YYYYMMDD';
export const timeFormat: string = 'HH:mm';

const _dateTime: string = 'YYYYMMDDHHmm';
export const displayDateFormat: string = 'DD/MM/YYYY';
export const displayDateTimeFormat: string = 'DD/MM/YYYY HH:mm';

export const parseDate = (value?: string): Moment | undefined => value ? moment(value, _dateTime) : undefined;
export const convertDate = (value: Moment) => value.format(_dateTime);
export const displayDate = (value: Moment | undefined) => value?.format(displayDateFormat) ?? undefined;
export const displayDateTime = (value: Moment | undefined) => value?.format(displayDateTimeFormat) ?? undefined;

export const combineDateTime = (date: Moment, time: String) => moment(date.format(dateFormat) + time, 'YYYYMMDDHH:mm');

export const getSlot = (value: Moment) => value.format('HH:mm');

export const formatStringToDate = (value?: string): string => displayDate(parseDate(value)) ?? 'Any date';
export const formatStringToDateTime = (value?: string): string => displayDateTime(parseDate(value)) ?? 'Any date';

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}