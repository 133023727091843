export default class TagDTO {
  id: number = 0;
  name: string = '';
  description: string = '';

  constructor(source: any) {
    if (source !== null && source !== undefined) {
      Object.assign(this, source);
    }
  }
}
