import React from 'react';
import {Popover} from "@mui/material";
import {Moment} from "moment";
import DatePicker from "./DatePicker";
import {TextInput} from "./Input";
import {displayDateFormat, displayDateTimeFormat} from "./utils";
import { useTranslation } from 'react-i18next';

type DateInputProps = {
  title?: string;
  name: string;
  value?: Moment;
  onChange: (newValue: Moment, name: string) => void;
  lastField?: boolean;
  longDate?: boolean;
  pickerType?: 'all' | 'past' | 'future';
  showTimePicker?: boolean;
  errorMessage?: string;
}

const DateInput = ({title, name, value, onChange, lastField, longDate, pickerType, showTimePicker, errorMessage}: DateInputProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let dateFormat = longDate ? displayDateTimeFormat : displayDateFormat;

  const timeChangeHandler = (date: Moment, closeOnSelection: boolean) =>{
    onChange(date, name);
    if(closeOnSelection){
      handleClose();
    }
  }

  return (
    <>
      <TextInput title={title ? title : ""} name={name} value={value ? value.format(dateFormat) : t('Select date')}
                 onClick={handleClick} readonly lastField={lastField} errorMessage={errorMessage}/>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          square: false,
          elevation: 1,
        }}
        BackdropProps={{invisible: false}}
      >
        <div style={{padding: 17}}>
          <DatePicker value={value} onChange={timeChangeHandler} pickerType={pickerType}
                      showTimePicker={showTimePicker}/>
        </div>
      </Popover>
    </>
  )
}

export default DateInput;