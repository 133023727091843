import React from 'react';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import PartyGrid from "../features/partyGrid/PartyGrid";


const render = (status) => {
  if (status === Status.FAILURE) return <div>error</div>;
  return <div>Loading</div>;
};

const center = {
  lat: 52.06854781011041,
  lng: 4.298944407632883
};

function MyMapComponent({
  center,
  zoom,
}) {
  const ref = React.useRef();

  React.useEffect(() => {
    new window.google.maps.Map(ref.current, {
      center,
      zoom,
    });
  });

  return <div ref={ref} id="map" className={'map'}/>;
}

const Search = () => {
  const useMap = false;

  return (
    <main>
      <div className="container">
        {useMap
            ? <div className="search-wrapper">
                <div style={{gridArea: 'a'}}>
                  <PartyGrid listClassName="search-page"/>
                </div>
                <div style={{gridArea: 'b'}}>
                  <Wrapper apiKey={"AIzaSyDsSAE6CgkfpsNKmwwYw88I8yCh3XEujW8"} render={render}>
                    <MyMapComponent center={center} zoom={12} />
                  </Wrapper>
                </div>
              </div>
            : <PartyGrid />
        }
      </div>
    </main>
  )
}

export default Search;
