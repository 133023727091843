import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import tagsSlice from '../components/tag/tagsBar/tagsSlice';
import partyGridSlice from '../features/partyGrid/partyGridSlice';
import partyViewSlice from '../pages/partyView/partyPageSlice';
import bookingPageSlice from '../features/booking/bookingPageSlice';

export const store = configureStore({
  reducer: {
    tags: tagsSlice,
    parties: partyGridSlice,
    party: partyViewSlice,
    booking: bookingPageSlice,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
