import * as service from "./vendor_service";
import BookingDTO from "../types/BookingDTO";
import {AxiosError} from "axios";
import RequestError from "../types/Error";

export async function submit(booking: BookingDTO) {
  let response = await service.submitBooking(booking);

  if(response instanceof AxiosError)
    throw new RequestError(response);

  return response.data;
}

export async function getConfirmation(bookingReference: string) {
  let response = await service.getBookingConfirmation(bookingReference);
  return response.data;
}