export default class RequestError /*extends Error*/ {
  code: string;
  message: string;
  status: number;
  data: string;

  constructor(source: any) {
    // super();
    this.code = source.code ?? '[Request error]';
    this.message = source.message ?? '[Request error]';
    this.status = source.response?.status ?? 400;
    this.data = source.response?.data ?? '[Request error]';
  }
  //
  // toString() {
  //   return JSON.stringify(this);
  // }
  //
  // toObject() {
  //   return {code: this.code, message: this.message, status: this.status, data: this.data, type: "RequestError"};
  // }
}