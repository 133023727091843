import axios from 'axios';
import {delay} from "../common/utils";
import {debugLog} from "../utils/debug_utils";

export const testDomainGet = async (url: string): Promise<any> => axios.get(url);

let isSent = false;
export const getList = async (url: string, search?: string, tags?: string, page?: number, pageSize?: number): Promise<any> => {
    try {
        while(isSent){
            await delay(300);
        }
        isSent = true;
        let params: {[key: string]: string } = {};

        if(search != null && search.length > 0){
            params['search'] = encodeURI(search);
        }

        if(tags != null && tags.length > 0){
            params['tags'] = tags;
        }

        if(page != null && page > 1){
            params['page'] = '' + page;
        }

        if(pageSize != null && pageSize !== 30){
            params['pageSize'] = pageSize + '';
        }

        let result = await axios.get(url, {params});
        isSent = false;
        return result;
    } catch (error) {
        isSent = false;
        debugLog(error);
        return false;
    }
}

export const postItem = async (url: string, data: any): Promise<any> => {
    try {
        while(isSent){
            await delay(300);
        }
        isSent = true;
        let result = await axios.post(url, data);
        isSent = false;
        return result;
    } catch (error) {
        isSent = false;
        debugLog(error);
        return error;
    }
}

export const getParentedList = async (url: string, id: number): Promise<any> => {
    try {
        while(isSent){
            await delay(300);
        }
        isSent = true;
        let result = await axios.get(`${url}/${id}`);
        isSent = false;
        return result;
    } catch (error) {
        isSent = false;
        debugLog(error);
        return false;
    }
}

export const getSingle = async (url: string, id: number | string): Promise<any> => {
    try {
        while(isSent){
            await delay(300);
        }
        isSent = true;
        let result = await axios.get(`${url}/${id}`);
        isSent = false;
        return result;
    } catch (error) {
        isSent = false;
        debugLog(error);
        return false;
    }
}

//
// export const putItem = async (url: string, data: any): Promise<any> => {
//     try {
//         if(isSent){
//             await delay(300);
//         }
//         isSent = true;
//         let result = await axios.put(url, data);
//         isSent = false;
//         return result;
//     } catch (error) {
//         isSent = false;
//         console.log(error);
//     }
// }
//
// export const deleteItem = async (url: string, id: number): Promise<any> => {
//     try {
//         if(isSent){
//             await delay(300);
//         }
//         isSent = true;
//         let result = await axios.delete(`${url}/${id}`);
//         isSent = false;
//         return result;
//     } catch (error) {
//         isSent = false;
//         console.log(error);
//     }
// }