import terms from "./TermsEN.json"
import RenderTerms from "../../components/renderTerms/RenderTerms";

const TermsAndConditions = () => {
    const data = terms.termsAndConditions;

    return (
         <RenderTerms
             pageTitle={data.pageTitle}
             caption={data.caption}
             tableOfContents={data.tableOfContents}
             main={data.main as []}
         />
    )
}

export default TermsAndConditions;