import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Moment} from "moment";
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "../../app/store";
import BookingDTO from "../../types/BookingDTO";
import PartyDTO from "../../types/PartyDTO";
import PartyOptionDTO from "../../types/PartyOptionDTO";
import API from '../../app/api';
import {convertDate} from '../../common/utils';
import {BookingExtraOptionDTO} from "../../types/PartyExtraOptionDTO";

class ValidationMessages {
  dateOfParty?: string;
  numberOfKids?: string;
  fullName?: string;
  email?: string;
  nameChild?: string;
  birthday?: string;
  agreement?: boolean;
  eventAddress?: string;
}

const emptyErrors: ValidationMessages = {
  dateOfParty: undefined,
  numberOfKids: undefined,
  fullName: undefined,
  email: undefined,
  nameChild: undefined,
  birthday: undefined,
}

export interface BookingPageState {
  selectedParty?: PartyDTO;
  booking: BookingDTO;
  status: 'idle' | 'loading' | 'failed';
  errors: ValidationMessages;
}

const emptyBooking: BookingDTO = {
  vendorId: 0,
  partyId: 0,
  partyOptionId: 0,
  fullName: '',
  email: '',
  numberOfKids: 1,
  eventAddress: '',

  nameChild: '',
  extraOptions: [],
  agreement: false,
  country: false,
}

export const initialState: BookingPageState = {
  selectedParty: undefined,
  booking: emptyBooking,
  status: 'idle',
  errors: emptyErrors,
};

const submitBooking = createAsyncThunk(
  'booking/submitBooking',
  async (booking: BookingDTO) => {
    return await API.submitBooking(booking);
  }
);

const loadParty = createAsyncThunk(
  'booking/loadParty',
  async (partyId: number) => {
    return await API.loadParty(partyId);
  }
);

export const bookingPageSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setParty: (state, action: PayloadAction<PartyDTO>) => {
      state.selectedParty = action.payload;
      state.booking.extraOptions = action.payload.extraOptions;
      state.booking.partyId = action.payload.id;
      state.booking.vendorId = action.payload.vendor.id;

      if (action.payload.options?.length) {
        let option = action.payload.options[0];
        state.booking.selectedPartyOption = option;
        state.booking.partyOptionId = option.id;
      }
    },
    setBooking: (state, action: PayloadAction<BookingDTO>) => {
      state.booking = action.payload;
    },
    setSelectedOption: (state, action: PayloadAction<PartyOptionDTO>) => {
      state.booking.selectedPartyOption = action.payload;
      state.booking.partyOptionId = action.payload.id;
    },
    setNumberOfKids: (state, action: PayloadAction<number>) => {
      state.booking.numberOfKids = action.payload;
    },
    setDateOfParty: (state, action: PayloadAction<string>) => {
      state.booking.dateOfParty = action.payload;
    },
    setBirthday: (state, action: PayloadAction<string>) => {
      state.booking.birthday = action.payload;
    },
    setErrors: (state, action: PayloadAction<ValidationMessages>) => {
      state.errors = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitBooking.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(submitBooking.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(submitBooking.rejected, (state, action) => {
        state.status = 'failed';
      })

      .addCase(loadParty.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadParty.fulfilled, (state, action) => {
        state.selectedParty = action.payload as PartyDTO;
        state.selectedParty.options.sort((a, b) => a.id < b.id ? -1 : 1);
        state.booking.partyId = state.selectedParty.id;
        state.booking.vendorId = state.selectedParty.vendor.id;
        state.booking.partyOptionId = state.selectedParty.options[0].id;
        state.booking.selectedPartyOption = state.selectedParty.options[0];
        state.booking.extraOptions = state.selectedParty.extraOptions
          .map((item) => {
            const result = item as BookingExtraOptionDTO
            result.value = 0;
            return result;
          });


        state.status = 'idle';
      })
      .addCase(loadParty.rejected, (state) => {
        state.status = 'failed';
      })
  },
});


export const actionsRef = {

  setParty: bookingPageSlice.actions.setParty,
  setBooking: bookingPageSlice.actions.setBooking,
  setErrors: bookingPageSlice.actions.setErrors,
  setSelectedOption: bookingPageSlice.actions.setSelectedOption,
  setNumberOfKids: bookingPageSlice.actions.setNumberOfKids,
  setDateOfParty: (value: Moment) => bookingPageSlice.actions.setDateOfParty(convertDate(value)),
  setBirthday: (value: Moment) => bookingPageSlice.actions.setBirthday(convertDate(value)),
  submitBooking: submitBooking,
  loadParty: loadParty,
}

export const stateRef = (state: RootState) => ({
  selectedParty: state.booking.selectedParty,
  booking: state.booking.booking,
  status: state.booking.status,
  errors: state.booking.errors,
});
export const stateWidgetRef = (state: RootState) => ({
  booking: state.booking.booking,
  status: state.booking.status
});

export const bookingPageConnector = connect(stateRef, actionsRef)
export const bookingWidgetConnector = connect(stateRef, actionsRef)
export type BookingPageProps = ConnectedProps<typeof bookingPageConnector>;
export type BookingWidgetStateProps = ConnectedProps<typeof bookingWidgetConnector>;

export default bookingPageSlice.reducer;
