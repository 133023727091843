import styles from "./TagsBar.module.css";
import React, {useId} from "react";

import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

import {tagsConnector, TagsProps} from './tagsSlice';
import TagDTO from "../../../types/TagDTO";
import {useNavigate, useSearchParams} from "react-router-dom";
import {arraysAreEqual, paramStringToNumberArray} from "../../../utils/array_utils";
import {createPartySearchParams} from "../../../utils/navigation_utils";

const TagsBar = (state: TagsProps) => {
  const id = useId();
  const loadTags = state.loadTags;
  const displayTags = state.getDisplayTags();
  const changeTagSelection = state.changeTagSelection;
  const setSelectedTags = state.setSelectedTags;

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const searchString = searchParams.get('search') ?? undefined;
  const selectedTagsList = paramStringToNumberArray(searchParams.get('tags') ?? '');

  React.useEffect(() => {
    if (!arraysAreEqual(state.selectedTags, selectedTagsList)) {
      setSelectedTags(selectedTagsList);
    }
  }, [searchParams, selectedTagsList, state.selectedTags, setSelectedTags]);

  React.useEffect(() => {
    if (state.tags.length === 0) {
      loadTags();
    }
  }, [state.tags.length, loadTags]);

  const onTagClick = (tag: TagDTO) => {
    changeTagSelection(tag);
    if(selectedTagsList.includes(tag.id)){
      selectedTagsList.splice(selectedTagsList.indexOf(tag.id), 1);
    } else {
      selectedTagsList.push(tag.id);
    }

    navigate({
      pathname: '/',
      search: createPartySearchParams(searchString, selectedTagsList),
    });
  }

  if (displayTags.length === 0) {
    return <></>
  }

  return (
    <>
      {displayTags &&
        <div className={styles.tagsBar}>
          <Splide
            className={styles.tagsBarCarousel}
            options={{
              autoWidth: true,
              drag: 'free',
              pagination: false,
              gap: '4px',
              padding: '0',
              classes: {
                arrow: 'splide__arrow ' + styles.tagsBarCarouselArrow,
                prev: 'splide__arrow--prev ' + styles.tagsBarCarouselArrowPrev,
                next: 'splide__arrow--next ' + styles.tagsBarCarouselArrowNext,
              }
            }}

          >
            {displayTags.map((tag: TagDTO, index: number) =>
              <SplideSlide
                className={styles.tagsBarCarouselItem}
                key={index}
              >
                <input
                  id={id + index + 'tagsCheck'}
                  className={styles.tagsBarInput + ' visually-hidden'}
                  type="checkbox"
                  onChange={() => onTagClick(tag)}
                  checked={state.selectedTags.includes(tag?.id)}
                />
                <label className={styles.tagsBarLabel} htmlFor={id + index + 'tagsCheck'}>
                  <span className={styles.hash}>#</span>
                  <span>{tag?.name}</span>
                </label>
              </SplideSlide>
            )}
          </Splide>
          {/*<div className={styles.tagsBarAction}>*/}
          {/*  <Button*/}
          {/*      modification={ButtonModification.tag}*/}
          {/*      iconBefore='filter'*/}
          {/*      text={'Filter'}*/}
          {/*      type={'button'}*/}
          {/*      action={() => action()}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
      }
    </>
  )
}

export default tagsConnector(TagsBar);