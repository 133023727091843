import PartyGrid from "../features/partyGrid/PartyGrid"
import TagsBar from "../components/tag/tagsBar/TagsBar";

const Home = () => {
  return (
    <main>
        <TagsBar />
        <section>
            <h1 className="visually-hidden">Some Main Page Title For SEO</h1>
            <div className="container">
                <PartyGrid />
            </div>
        </section>
    </main>
  )
}

export default Home;
