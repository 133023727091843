import {TextAreaInput, TextInput} from "../../common/Input";
import React from "react";
import Button, {ButtonModification} from "../button/Button";
import {ModalCloseType, useCloseModalMessage} from "../modal/ModalWindowContext";
import styles from "./ContactUs.module.css"
import {validateEmail, validateFullName, validateString} from "../../utils/BookingValidationUtils";
import {useTranslation} from "react-i18next";
import {sendContactUsMessage} from "../../service/message_service";
import ContactUsMessageDTO from "../../types/ContactUsMessageDTO";
import {debugLog} from "../../utils/debug_utils";

type ContactUsFormState = {
  message?: string,
  form: {
    fullName: string,
    email: string,
    message: string;
  },
  errors: {
    fullName?: string,
    email?: string,
    message?: string,
  }
}

const defaultState = {
  message: undefined,
  form: {
    fullName: "",
    email: "",
    message: ""
  },
  errors: {
    fullName: undefined,
    email: undefined,
    message: undefined,
  }
} as ContactUsFormState

const ContactUs = () => {
  const [state, setState] = React.useState<ContactUsFormState>(defaultState);
  const {t} = useTranslation();
  const closeMessage = useCloseModalMessage();


  // let state = defaultState;

  const handleChange = (newValue: string, name: string): boolean => {
    let propertyChange = {[name]: newValue};

    let newState = {...state, ...{form: {...state.form, ...propertyChange}}};

    let isValid: boolean;

    [newState, isValid] = validateField(newState, name);

    setState(newState);
    return isValid;
  }

  const validateField = (state: ContactUsFormState, name: string): [state: ContactUsFormState, isValid: boolean] => {
    let form = state.form;
    let errors = {...state.errors};

    let isValid: boolean = true;

    switch (name) {
      case "fullName":
        [errors.fullName, isValid] = validateFullName(form.fullName);
        break;
      case "email":
        [errors.email, isValid] = validateEmail(form.email);
        break;
      case "message":
        [errors.message, isValid] = validateString(form.message, t('Message is required'), true);
        break;
    }

    return [{form, errors}, isValid];
  }

  const validate = (): boolean => {
    let newState = {...state};

    let result = true;
    let isValid: boolean;

    [newState, isValid] = validateField(newState, 'fullName');
    result = result && isValid;
    [newState, isValid] = validateField(newState, 'email');
    result = result && isValid;
    [newState, isValid] = validateField(newState, 'message');
    result = result && isValid;

    setState(newState);

    return result;
  }

  const submitForm = async () => {
    if (!validate()) {
      return;
    }

    setState({...state, ...{message: 'Message is on its way...'}});

    let result = await sendContactUsMessage(new ContactUsMessageDTO(state.form));

    if (result instanceof Error || result.error == null) {
      setState(defaultState);
      closeMessage(ModalCloseType.ok);
    } else {
      setState({...state, ...{message: 'Service error! Please try again later'}})
      debugLog(result);
    }
  }


  return (
    <>
      <form className={styles.form}>
        {state.message && <div>{state.message}</div>}
        <TextInput
          title={t("Your name")}
          name="fullName"
          value={state.form.fullName}
          onChange={handleChange}
          errorMessage={state.errors.fullName}
        />
        <TextInput
          title={t("Your email")}
          name="email"
          value={state.form.email}
          onChange={handleChange}
          errorMessage={state.errors.email}
        />
        <TextAreaInput
          title={t('Message')}
          name='message'
          value={state.form.message}
          onChange={handleChange}
          errorMessage={state.errors.message}
        />
        <div className={styles.actions}>
          <Button
            modification={[ButtonModification.bordered, ButtonModification.error]}
            text={t('Cancel')}
            action={async (event: React.MouseEvent) => {
              closeMessage(ModalCloseType.cancel);
            }}
          />
          <Button
            modification={[ButtonModification.filled, ButtonModification.fluid, ButtonModification.shadow]}
            text={t('Send message')}
            action={async (event: React.MouseEvent) => {
              await submitForm();
            }}
          />
        </div>
      </form>
    </>
  )
}

export default ContactUs;
