import React from 'react';
import { useTranslation } from 'react-i18next';

import * as SiteRoutes from '../../../app/Routes';
import {loadStripe, PaymentIntentResult, StripeElementsOptions,} from "@stripe/stripe-js";
import {Elements, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";

import CheckoutBlock from '../CheckoutBlock/CheckoutBlock';

import Api from '../../../app/api';
import BookingConfirmation from '../../../types/BookingConfirmation';
import {useParams} from 'react-router-dom';
import NoMatch from '../../../pages/404/404';
import styles from './BookingConfirmationPage.module.css';

const stripePromise = loadStripe("pk_test_51KS8jiJHT6ygutoc3BZjzyVwxmp1FyvTOWkx438K1PtGRH43NeRqDD7nyRG9ZUoIfRLoqCwTzfgpKv3gZktkbRFA00WA3Ll1Uk");

const BookingConfirmationPage = () => {
    const [clientSecret, setClientSecret] = React.useState<string>();
    const [bookingConfirmation, setBookingConfirmation] = React.useState<BookingConfirmation>();
    const { bookingId } = useParams();

    const options: StripeElementsOptions = {
        clientSecret,
        appearance: {
            theme: 'stripe',
            variables: {
                colorPrimary: 'rgb(18, 204, 148)',
                colorBackground: '#ffffff',
                colorText: '##222222',
                colorDanger: '#df1b41',
                fontFamily: 'Maven Pro',
                spacingUnit: '5px',
                borderRadius: '4px'
            }
        },
        fonts: [
            { cssSrc: 'https://fonts.googleapis.com/css?family=Maven+Pro' }
        ]
    };

    React.useEffect(() => {
        if (bookingId)
            Api.getBookingConfirmation(bookingId).then((result: BookingConfirmation) => {
                setClientSecret(result.clientSecret);
                setBookingConfirmation(result);
            });
    }, [bookingId]);

    if (!bookingId)
        return <NoMatch />;

    return (
        <main>
            <div className={styles.wrapper + ' container'}>
                <div className={styles.container}>
                    <div className={styles.content}>
                        {bookingConfirmation &&
                            <CheckoutBlock bookingConfirmation={bookingConfirmation} showPartyDetails />
                        }
                    </div>
                </div>
                <div className={styles.container}>
                    <div className={styles.content}>
                        {clientSecret && (
                            <Elements options={options} stripe={stripePromise} >
                                <CheckoutForm />
                            </Elements>
                        )}
                    </div>
                </div>
            </div>
        </main>
    )
}

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const { t } = useTranslation();


    const [message, setMessage] = React.useState<string>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }: PaymentIntentResult) => {
            switch (paymentIntent?.status) {
                case "succeeded":
                    setMessage(t("Payment succeeded!"));
                    break;
                case "processing":
                    setMessage(t("Your payment is processing."));
                    break;
                case "requires_payment_method":
                    setMessage(t("Your payment was not successful, please try again."));
                    break;
                default:
                    setMessage(t("Something went wrong."));
                    break;
            }
        });
    }, [stripe, t]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!stripe || !elements)
            return;

        setIsLoading(true);

        // console.log(SiteRoutes.GET_BOOKING_CONFIRMATION_DONE_URL(true));
        // setTimeout(() => console.log('continue'), 3000);
        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {

                // Make sure to change this to your payment completion page
                return_url: SiteRoutes.GET_BOOKING_CONFIRMATION_DONE_URL(true),
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage(t("An unexpected error occurred."));
        }

        setIsLoading(false);
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit} className={styles.paymentForm} >
            <PaymentElement id="payment-element" options={{ layout: "tabs" }} />
            <button disabled={isLoading || !stripe || !elements} id="submit" className={styles.buttonPayment}>
                <span id="button-text">
                    {isLoading ? <span className="spinner" id="spinner"></span> : "Pay now"}
                </span>
            </button>
            {/* Show any error or success messages */}
            {message && <p id="payment-message">{message}</p>}
        </form>
    );
}

export default BookingConfirmationPage;