import React from 'react';
import './App.css';

import { Routes, Route, Outlet } from "react-router-dom";
import Page404 from './pages/404/404';
import Home from './pages/Home';
import PartyPage from './pages/partyView/PartyPage';
import Search from './pages/Search';
import UserRegistration from './pages/UserRegistration/UserRegistration';
import BookingPage from './features/booking/BookingPage';
import BookingDonePage from './features/booking/bookingDone/BookingDonePage';
import BookingConfirmationPage from './features/booking/BookingConfirmationPage/BookingConfirmationPage';
import BookingCompletePage from './features/booking/BookingCompletePage';
import Header from './components/header/Header'
import Footer from './components/footer/Footer'

import * as SiteRoutes from './app/Routes';
import Activation from "./pages/Activation";
import CompanyRegistration from "./pages/CompanyRegistration/CompanyRegistration";
import {ModalWindowProvider} from "./components/modal/ModalWindowContext";
import packageData from '../package.json';
import TermsAndConditions from "./pages/terms/TermsAndConditions";
import ExtenuatingCircumstancesPolicy from "./pages/terms/ExtenuatingCircumstancesPolicy";
import RebookingAndRefundPolicy from "./pages/terms/RebookingAndRefundPolicy";
import VendorCancellationPolicy from "./pages/terms/VendorCancellationPolicy";
import CommunityDisturbancePolicy from "./pages/terms/CommunityDisturbancePolicy";
import KidsvenuContentPolicy from "./pages/terms/KidsvenuContentPolicy";
import KidsvenuReviewsPolicy from "./pages/terms/KidsvenuReviewsPolicy";
import NondiscriminationPolicy from "./pages/terms/NondiscriminationPolicy";
import OurCommunityStandards from "./pages/terms/OurCommunityStandards";
import PrivacyPolicy from "./pages/terms/PrivacyPolicy";

const Layout = () => {
      return (
        <ModalWindowProvider
          // isClosing={true}
          // isShow={true}
          // title={'test modal'}
          // content={
          //   '<div>' +
          //   '<ul>' +
          //   '<li>test</li><li>test</li><li>test</li><li>test</li><li>test</li><li>test</li>' +
          //   '<li>test</li><li>test</li><li>test</li><li>test</li><li>test</li><li>test</li>' +
          //   '<li>test</li><li>test</li><li>test</li><li>test</li><li>test</li><li>test</li>' +
          //   '</ul>' +
          //   '</div>'
          // }
          // cancelButton={{ text: 'CANCEL'}}
          // icon={'info'}
        >
          <Header />
          <Outlet />
          <Footer version={packageData.version}/>
        </ModalWindowProvider>
  )
}


const App = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index element={<Home />} />
        <Route path={SiteRoutes.PARTY_BOOK_DONE_ROUTE} element={<BookingDonePage />} />
        <Route path={SiteRoutes.PARTY_BOOK_ROUTE} element={<BookingPage />} />
        <Route path={SiteRoutes.PARTY_ROUTE} element={<PartyPage />} />
        <Route path={SiteRoutes.SEARCH_ROUTE} element={<Search />} />
        <Route path={SiteRoutes.USER_REGISTRATION_ROUTE} element={<UserRegistration />} />
        <Route path={SiteRoutes.COMPANY_REGISTRATION_ROUTE} element={<CompanyRegistration />} />
        <Route path={SiteRoutes.ACTIVATION_ROUTE} element={<Activation />} />
        <Route path={SiteRoutes.BOOKING_CONFIRMATION_ROUTE} element={<BookingConfirmationPage />} />
        <Route path={SiteRoutes.BOOKING_CONFIRMATION_DONE_ROUTE} element={<BookingCompletePage />} />
        <Route path={SiteRoutes.TERMS_AND_CONDITIONS_ROUTE} element={<TermsAndConditions />} />
        <Route path={SiteRoutes.EXTENUATING_CIRCUMSTANCES_POLICY_ROUTE} element={<ExtenuatingCircumstancesPolicy />} />
        <Route path={SiteRoutes.REBOOKING_AND_REFUND_POLICY_ROUTE} element={<RebookingAndRefundPolicy />} />
        <Route path={SiteRoutes.VENDOR_CANCELLATION_POLICY_ROUTE} element={<VendorCancellationPolicy />} />
        <Route path={SiteRoutes.COMMUNITY_DISTURBANCE_POLICY_ROUTE} element={<CommunityDisturbancePolicy />} />
        <Route path={SiteRoutes.KIDSVENU_CONTENT_POLICY_ROUTE} element={<KidsvenuContentPolicy />} />
        <Route path={SiteRoutes.KIDSVENU_REVIEWS_POLICY_ROUTE} element={<KidsvenuReviewsPolicy />} />
        <Route path={SiteRoutes.NONDISCRIMINATION_POLICY_ROUTE} element={<NondiscriminationPolicy />} />
        <Route path={SiteRoutes.OUR_COMMUNITY_STANDARDS_ROUTE} element={<OurCommunityStandards />} />
        <Route path={SiteRoutes.PRIVACY_POLICY_ROUTE} element={<PrivacyPolicy />} />
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}

export default App;
