import React, {ChangeEvent, useState} from "react";
import styles from "./UserRegistration.module.css";
import {UserRegistrationDTO} from "../../types/UserRegistrationDTO";

const UserRegistration = () => {
  const [user, setUser] = useState(new UserRegistrationDTO());

  // States for checking the errors
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  // Handling the name change
  const handleName = (e: ChangeEvent<HTMLInputElement>) => {
    setUser(user.copyWithName(e.target.value));
    setSubmitted(false);
  };

  // Handling the email change
  const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setUser(user.copyWithEmail(e.target.value));
    setSubmitted(false);
  };

  // Handling the password change
  // const handlePassword = (e: ChangeEvent<HTMLInputElement>) => {
  //   setPassword(e.target.value);
  //   setSubmitted(false);
  // };

  // Handling the form submission
  const handleSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();

    let message = validateName(user.name);
    if(message.length > 0) message += '\n';
    message += validateEmail(user.email);

    if(message) {
      setError(message);
    } else {
      // let result = await postItem(USER_REGISTER, user);
    }


    // if (message.length > 0) {
    //
    // } else {
    //   setError(false);
    // }

  };

  // Showing success message
  const successMessage = () => {
    return (
      <div
        className="success"
        style={{
          display: submitted ? "" : "none",
        }}
      >
        <h1>User {user.name} successfully registered!!</h1>
      </div>
    );
  };

  // Showing error message if error is true
  const errorMessage = () => {
    return (
      <div
        className={styles.error}
        style={{ display: error ? "" : "none",}}
      >
        <h4>{error}</h4>
      </div>
    );
  };

  const validateEmail = (email: string): string => {
    if(!email) return 'email is required!';

    return "";
  }

  const validateName = (name: string): string => {
    if(!name) return 'name is required!';

    return "";
  }

  return (
    <div className={styles.form}>
      <div>
        <h1>User registration</h1>
      </div>

      {/* Calling to the methods */}
      <div className={styles.messages}>
        {errorMessage()}
        {successMessage()}
      </div>

      <form>
        {/* Labels and inputs for form data */}
        <label className={styles.label}>Name</label>
        <input
          onChange={handleName}
          className={styles.input}
          value={user.name}
          type="text"
        />

        <label className={styles.label}>Email</label>
        <input
          onChange={handleEmail}
          className={styles.input}
          value={user.email}
          type="email"
        />

        <label className={styles.label} style={{color: '#FF5577'}} >ADD CAPTCHA</label>

        <button onClick={handleSubmit} className={styles.btn} type="submit">
          Register
        </button>
      </form>
    </div>
  );
}

export default UserRegistration;