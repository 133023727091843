import {createSearchParams} from "react-router-dom";
import {arrayToParamString} from "./array_utils";

export const createPartySearchParams = (search: string | undefined, tags: number[] | undefined): string => {

  let params: { [key: string]: string } = {};
  if (search) {
    params['search'] = encodeURI(search);
  }

  if (tags && tags.length > 0) {
    params['tags'] = arrayToParamString(tags);
  }

  return `?${createSearchParams(params)}`;
}