import styles from "./RenderTerms.module.css";
import parse from "html-react-parser";

type RenderTermsProps = {
    pageTitle?: string;
    caption?: string;
    tableOfContents?: string;
    main?: [];
}

const RenderTerms = ({
    pageTitle,
    caption,
    tableOfContents,
    main,
}: RenderTermsProps) => {
    const clickHandler = (event?: any) => {
        event.preventDefault();
        const target = event.target.href.split("#").slice(-1).join(" ");
        const element = document.getElementById(target);
        element && element.scrollIntoView({behavior: "smooth", block: "start"});
    }

    return (
        <main>
            <div className={'container'}>
                <div className={styles.main}>
                    {pageTitle && <h1>{parse(pageTitle)}</h1>}
                    {caption && parse(caption)}

                    {tableOfContents && <p><strong>{tableOfContents}</strong></p>}
                    {tableOfContents && main &&
                        <ol>
                            {main.map((section: {id?: string, title?: string, chapters: []}, index) =>
                                <li key={index}>
                                    <a
                                        href={'#' + section.id}
                                        onClick={(event) => clickHandler(event)}
                                    >
                                        {section.title}
                                    </a>
                                    {section.chapters.length &&
                                        <ol>
                                            {section.chapters.map((chapter: {id?: string, title?: string, subchapters: []}, index) =>
                                                <li key={index}>
                                                    <a
                                                        href={'#' + chapter.id}
                                                        onClick={(event) => clickHandler(event)}
                                                    >
                                                        {chapter.title}
                                                    </a>
                                                    {chapter.subchapters &&
                                                        <ol>
                                                            {chapter.subchapters.map((subchapter: {id?: string, title?: string, sub2level: []}, index) =>
                                                                <li key={index}>
                                                                    <a
                                                                        href={'#' + subchapter.id}
                                                                        onClick={(event) => clickHandler(event)}
                                                                    >
                                                                        {subchapter.title}
                                                                    </a>
                                                                    {subchapter.sub2level &&
                                                                        <ol>
                                                                            {subchapter.sub2level.map((sub2: {id?: string; title?: string}, index) =>
                                                                                <li key={index}>
                                                                                    <a
                                                                                        href={'#' + sub2.id}
                                                                                        onClick={(event) => clickHandler(event)}
                                                                                    >
                                                                                        {sub2.title}
                                                                                    </a>
                                                                                </li>
                                                                            )}
                                                                        </ol>
                                                                    }
                                                                </li>
                                                            )}
                                                        </ol>
                                                    }
                                                </li>
                                            )}
                                        </ol>
                                    }
                                </li>
                            )}
                        </ol>
                    }

                    {main && main.map((section: {id?: string, title?: string, caption?: string, chapters: []}, index) =>
                        <section key={index}>
                            {section.title && <h2 id={section.id && section.id}>{parse(section.title)}</h2>}
                            {section.caption && parse(section.caption)}
                            {section.chapters && section.chapters.map((chapter: {id?: string, title?: string, caption?: string, subchapters: []}, index) =>
                                <div key={index}>
                                    {chapter.title && <h3 id={chapter.id && chapter.id}>{parse(chapter.title)}</h3>}
                                    {chapter.caption && parse(chapter.caption)}
                                    {chapter.subchapters && chapter.subchapters.map((subchapter: {id?: string, title?: string, content?: string, sub2level: []}, index) =>
                                        <div key={index}>
                                            {subchapter.title &&
                                                <h4 id={subchapter.id && subchapter.id}>{parse(subchapter.title)}</h4>}
                                            {subchapter.content && parse(subchapter.content)}
                                            {subchapter.sub2level && subchapter.sub2level.map((sub2: {id?: string, title?: string, content?: string}, index) =>
                                                <div key={index}>
                                                    {sub2.title && <h5 id={sub2.id}>{parse(sub2.title)}</h5>}
                                                    {sub2.content && parse(sub2.content)}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </section>
                    )}
                </div>
            </div>
        </main>
    )
}

export default RenderTerms;