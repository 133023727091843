import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';
import styles from './Rating.module.css';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { useId } from "react";

type RatingProps = {
    rating?: number;
    review?: number;
    tooltipPlace?: string;
}
const Rating = ({ rating, review, tooltipPlace }: RatingProps) => {
    const { t } = useTranslation();
    const id = useId();
    return (
        <div className={styles._main + ' ' + id} data-tooltip-id={id}>
            <FontAwesomeIcon width={14} height={14} className={styles._icon} icon="star"/>
            <p className={styles._text}>{rating || <span className={styles.noRates}>No rates</span>}</p>
            <Tooltip id={id} place={tooltipPlace as any || "top"}>
                ({review || 0} {t('reviews')})
            </Tooltip>
        </div>
    )
}

export default Rating;