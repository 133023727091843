import React from 'react';
import { useTranslation } from 'react-i18next';
import { bookingPageConnector, BookingPageProps } from './bookingPageSlice';

const BookingCompletePage = (state: BookingPageProps) => {

    const { t } = useTranslation();

    return (
        <div style={{ position: 'fixed', top: 0, left:0, right: 0, bottom: 0, display: 'flex',  justifyContent: 'center', alignItems: 'center'}}>
            <div style={{maxWidth: 500, display: 'flex', flexDirection: 'column'}}>
                <div style={{textAlign: 'center', fontSize: '32px', lineHeight: '38px', marginTop: '62px', fontWeight: 700}}>{t('You booked a party')}</div>
                <div style={{textAlign: 'center', lineHeight: '36px', marginTop: '9px'}}>{t('Your booking is compelte')}</div>
                <button style={{ alignSelf: 'center', margin: '15px 0', backgroundColor: 'rgb(18, 204, 148)', padding: '13px 24px', border: '1px solid #d9d9d9', borderRadius: '16px', fontSize: 16,  color: '#FFF', fontFamily: "Maven Pro", fontWeight: 500 }}>{t('Back to Main Page')}</button>
            </div>
        </div>
    )
}

export default bookingPageConnector(BookingCompletePage);