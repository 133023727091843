import React from 'react';
import { useTranslation } from 'react-i18next';
import Image from '../../../components/image/Image';
import Rating from '../../../components/rating/Rating';
import {BookingExtraOptionDTO} from '../../../types/PartyExtraOptionDTO';

import styles from './CheckoutBlock.module.css';
// import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import {formatStringToDateTime} from "../../../common/utils";
import BookingConfirmationDTO from "../../../types/BookingConfirmation";
import {getImageUrl, ImageFormat, ImageSize} from "../../../utils/ImageUtils";
import {PriceType} from "../../../types/PriceDTO";

type CheckoutBlockProps = {
  bookingConfirmation: BookingConfirmationDTO;
  showPartyDetails?: boolean;
  showPartyDescription?: boolean;
}

const CheckoutBlock = (state: CheckoutBlockProps) => {

  const { t } = useTranslation();

  // let [totalOptionCost, setTotalOptionCost] = React.useState<number>();
  // let [totalCost, setTotalCost] = React.useState<number>();

  // React.useEffect(() => {
  //   let total: number = 0;
  //   let totalOption: number = 0;
  //
  //   if (state.bookingConfirmation.partyOption && state.bookingConfirmation.partyOption.price && state.bookingConfirmation.numberOfKids) {
  //
  //     totalOption = state.bookingConfirmation.partyOption.price;
  //
  //     if (state.bookingConfirmation.partyOption.priceType === 'pp') {
  //       totalOption *= state.bookingConfirmation.numberOfKids;
  //     }
  //
  //
  //     if (state.bookingConfirmation.partyOption.minAmount && totalOption < state.bookingConfirmation.partyOption.minAmount)
  //       totalOption = state.bookingConfirmation.partyOption.minAmount;
  //
  //     if (state.bookingConfirmation.partyOption.maxAmount && totalOption > state.bookingConfirmation.partyOption.maxAmount)
  //       totalOption = state.bookingConfirmation.partyOption.maxAmount;
  //
  //     total += totalOption;
  //
  //     for (let option of state.bookingConfirmation.extraOptions) {
  //       if (option.value) {
  //         total += option.value * option.partyExtraOption!.price;
  //       }
  //     }
  //   }
  //
  //   setTotalOptionCost(+totalOption.toFixed(3))
  //   setTotalCost(+total.toFixed(3));
  //
  // }, [state])

  return (
    <>
      <div className={styles.checkout}>
        <Image
          className={styles.picture}
          imageSource={getImageUrl(state.bookingConfirmation.party!.cover?.url, ImageSize.SM, ImageFormat.JPG)}
          imageSource2x={getImageUrl(state.bookingConfirmation.party!.cover?.url, ImageSize.LG, ImageFormat.JPG)}
          imageSourceWebp={getImageUrl(state.bookingConfirmation.party!.cover?.url, ImageSize.SM, ImageFormat.WEBP)}
          imageSourceWebp2x={getImageUrl(state.bookingConfirmation.party!.cover?.url, ImageSize.LG, ImageFormat.WEBP)}
        />
        <div className={styles.content}>
          <p className={styles.title}>{state.bookingConfirmation.party!.title}</p>
          <div className={styles.rating}>
            <p className={styles.vendorName}>{state.bookingConfirmation.vendor!.title}</p>
            <Rating rating={state.bookingConfirmation.party!.rating}/>
          </div>
          {/*<div className={styles.location}>*/}
          {/*  <LocationOnOutlinedIcon/>*/}
          {/*  <p>{state.bookingConfirmation.party!.location}</p>*/}
          {/*</div>*/}
          <div className={styles.details}>
            <p>{t('Date of party')}:
              <span>
                {formatStringToDateTime(state.bookingConfirmation.dateOfParty)}
              </span>
            </p>

            <p>{t('Number of kids')}:
              <span>
                {state.bookingConfirmation.numberOfKids}
              </span>
            </p>
          </div>
          <div className={styles.counter}>
            <p>{state.bookingConfirmation.partyOption.title}<span>€ {state.bookingConfirmation.partyOption.price}</span>
            </p>
            {
                state.bookingConfirmation.partyOption.priceType === PriceType.PP &&
                <p>&nbsp; x {state.bookingConfirmation.numberOfKids} {t('kids')}
                  <span>€ {state.bookingConfirmation.price}</span></p>
            }
            {
              state.bookingConfirmation.extraOptions.filter(item => !!item.value).map((option: BookingExtraOptionDTO) => {
                return (
                  <div key={option.partyExtraOption!.id}>
                    <p>{option.partyExtraOption!.title} x {option.value}
                      <span>€ {option.value! * option.partyExtraOption!.price}</span>
                    </p>
                  </div>
                )
              })
            }
            <p>{t('KidsVenu fee')}
              <span>€ {state.bookingConfirmation.fee ? state.bookingConfirmation.fee : '0'}</span>
            </p>
          </div>
          <p className={styles.total}>{t('Total')}
            <span>€ {state.bookingConfirmation.price}</span>
          </p>
        </div>
      </div>
    </>
  )
}

export default CheckoutBlock;