import React from "react";
import {Navigation, Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Carousel.css';
import PartyCard from "../../features/partyGrid/PartyCard";
import {ReactComponent as ArrowBeforeIcon} from '../../icons/arrow-before.svg';
import {ReactComponent as ArrowAfterIcon} from '../../icons/arrow-after.svg';
import PartyDTO from "../../types/PartyDTO";

type CarouselProps = {
    items?: PartyDTO[];
};

const Carousel = ({items}: CarouselProps) => {
    return (<Swiper
        className={'Carousel_PartyCard'}
        modules={[Navigation, Pagination]}
        spaceBetween={8}
        navigation={{nextEl: `button.Carousel_PartyCard__button-next`, prevEl: 'button.Carousel_PartyCard__button-prev', speed: 500}}
        pagination={{clickable: true, bulletElement: 'button'}}
        wrapperTag={'ul'}
        breakpoints={{
            320: {slidesPerView: 1},
            640: {slidesPerView: 2},
            992: {slidesPerView: 3},
            1440: {slidesPerView: 4}
        }}
    >
        {items?.map((item: PartyDTO, index: number) =>
            <SwiperSlide
                className={'Carousel_PartyCard__slide'}
                tag={'li'}
                key={index}
            >
                <PartyCard party={item}/>
            </SwiperSlide>
        )}
        <button
            className={'Carousel_PartyCard__button Carousel_PartyCard__button-prev'}
            aria-label={'previous slide'}
        >
            <ArrowBeforeIcon/>
        </button>
        <button
            className={'Carousel_PartyCard__button Carousel_PartyCard__button-next'}
            aria-label={'next slide'}
        >
            <ArrowAfterIcon/>
        </button>
    </Swiper>);
}

export default Carousel;