import React from 'react';
import PartyDTO from "../../types/PartyDTO"
import PartyCard from "./PartyCard"
import {PartyGridProps, partyGridConnector} from "./partyGridSlice"
import styles from './PartyGrid.module.css';
import {useSearchParams} from "react-router-dom";


const PartyGrid = (state: PartyGridProps & { listClassName?: string }) => {
  const loadParties = state.loadParties;
  const setPartyTags = state.setPartyTags;
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    loadParties({search: searchParams.get('search'), tags: searchParams.get('tags')});
  }, [loadParties, searchParams])

  React.useEffect(() => {
    setPartyTags(state.tags);
  }, [state.tags, setPartyTags])

  return (
    <div className={styles._wrapper}>
      <ul className={
        state.listClassName === 'search-page'
          ? (styles._list + ' ' + styles._grid_search)
          : styles._list
      }>
        {state.parties ? state.parties.map((party: PartyDTO) =>
            <li key={party.id} className={styles._item}>
              <PartyCard party={party} modifier={state.listClassName ? "search-list" : ''}/>
            </li>
          ) :
          'Nothing there yet'
        }
      </ul>
      {/*<button className={styles._action} style={{display: 'none'}} type={"button"}>Show more</button>*/}
    </div>
  )
}

export default partyGridConnector(PartyGrid);
