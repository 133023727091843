import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Image from '../image/Image';
import ImageOverlay from '../imageOverlay/ImageOverlay';
import styles from './ImageGallery.module.css';
import './ImageGallery.css';
import { ReactComponent as ButtonIcon } from '../../icons/button-tabs.svg';
import { getImageUrl, ImageFormat, ImageSize } from "../../utils/ImageUtils";

type ImageGalleryProps = {
  pictures: string[];
  title?: string;
}

const ImageGallery = ({ pictures, title }: ImageGalleryProps) => {
  const [openImages, setOpenImages] = useState(false); 
  const { t } = useTranslation();

  const myInput = useRef(null);

  const clickFancybox = () => {
    const imagesArr = document.querySelectorAll('[data-fancybox]');
    (imagesArr[0].children[0].children[0] as any).click();

    setTimeout(() => {
      (imagesArr[0].children[0].children[0] as any).click();
    }, 1);
  }

  const getImageWidth = (length: number, index: number, coefficient?: number) => {
    let size = length === 1 ? 1120 : length >= 5 && index !== 0 ? 274 : 556;

    return coefficient ? size * coefficient : size
  }

  const getImageHeight = (length: number, index: number, coefficient?: number) => {
    let size = length <= 2 || (length === 3 && index === 0) || (length >= 5 && index === 0) ? 556 : 274;

    return coefficient ? size * coefficient : size;
  }

  return (
    <div className={styles._wrapper} ref={myInput}>
      {!pictures.length &&
        <ImageOverlay />
      }
      {pictures.length > 0 && pictures.length <= 5 ?
        <div className={styles._grid + ' ImageGallery_' + pictures.length}>
          {pictures.map((picture, index) =>
            <Image
              imageSource={getImageUrl(picture, new ImageSize(getImageWidth(pictures.length, index) + 'x' + getImageHeight(pictures.length, index)), ImageFormat.JPG)}
              imageSource2x={getImageUrl(picture, new ImageSize(getImageWidth(pictures.length, index, 2) + 'x' + getImageHeight(pictures.length, index, 2)), ImageFormat.JPG)}
              imageSourceWebp={getImageUrl(picture, new ImageSize(getImageWidth(pictures.length, index) + 'x' + getImageHeight(pictures.length, index)), ImageFormat.WEBP)}
              imageSourceWebp2x={getImageUrl(picture, new ImageSize(getImageWidth(pictures.length, index, 2) + 'x' + getImageHeight(pictures.length, index, 2)), ImageFormat.WEBP)}
              imageMobile={getImageUrl(picture, new ImageSize(getImageWidth(pictures.length, index, 0.5) + 'x' + getImageHeight(pictures.length, index, 0.5)), ImageFormat.JPG)}
              imageMobile2x={getImageUrl(picture, new ImageSize(getImageWidth(pictures.length, index) + 'x' + getImageHeight(pictures.length, index)), ImageFormat.JPG)}
              imageMobileWebp={getImageUrl(picture, new ImageSize(getImageWidth(pictures.length, index, 0.5) + 'x' + getImageHeight(pictures.length, index, 0.5)), ImageFormat.WEBP)}
              imageMobileWebp2x={getImageUrl(picture, new ImageSize(getImageWidth(pictures.length, index) + 'x' + getImageHeight(pictures.length, index)), ImageFormat.WEBP)}
              width={getImageWidth(pictures.length, index)}
              height={getImageHeight(pictures.length, index)}
              imageFancy={getImageUrl(picture, ImageSize.XXL, ImageFormat.JPG)}
              alt={title + ' image ' + (index + 1)}
              useFancy={'gallery'}
              key={index}
            />
          )}
        </div> : ''
      }
      {pictures.length > 5 &&
        <>
          <div className={styles._grid + ' ImageGallery_5'}>
            {pictures.slice(0, 5).map((picture, index) =>
              <Image
                onClick={() => {
                  setOpenImages(true);
                }}
                imageSource={getImageUrl(picture, new ImageSize(getImageWidth(pictures.length, index) + 'x' + getImageHeight(pictures.length, index)), ImageFormat.JPG)}
                imageSource2x={getImageUrl(picture, new ImageSize(getImageWidth(pictures.length, index, 2) + 'x' + getImageHeight(pictures.length, index, 2)), ImageFormat.JPG)}
                imageSourceWebp={getImageUrl(picture, new ImageSize(getImageWidth(pictures.length, index) + 'x' + getImageHeight(pictures.length, index)), ImageFormat.WEBP)}
                imageSourceWebp2x={getImageUrl(picture, new ImageSize(getImageWidth(pictures.length, index, 2) + 'x' + getImageHeight(pictures.length, index, 2)), ImageFormat.WEBP)}
                imageMobile={getImageUrl(picture, new ImageSize(getImageWidth(pictures.length, index, 0.5) + 'x' + getImageHeight(pictures.length, index, 0.5)), ImageFormat.JPG)}
                imageMobile2x={getImageUrl(picture, new ImageSize(getImageWidth(pictures.length, index) + 'x' + getImageHeight(pictures.length, index)), ImageFormat.JPG)}
                imageMobileWebp={getImageUrl(picture, new ImageSize(getImageWidth(pictures.length, index, 0.5) + 'x' + getImageHeight(pictures.length, index, 0.5)), ImageFormat.WEBP)}
                imageMobileWebp2x={getImageUrl(picture, new ImageSize(getImageWidth(pictures.length, index) + 'x' + getImageHeight(pictures.length, index)), ImageFormat.WEBP)}
                width={getImageWidth(pictures.length, index)}
                height={getImageHeight(pictures.length, index)}
                alt={title + ' image ' + (index + 1)}
                imageFancy={getImageUrl(picture, ImageSize.XXL, ImageFormat.JPG)}
                useFancy={'gallery'}
                key={index}
              />
            )}
          </div>
          <button className={styles._button} onClick={() => { clickFancybox(); }}>
            <ButtonIcon />
            <span>{t('Show all pictures')}</span>
          </button>
          {openImages &&
            <div className={styles._grid + ' ImageGallery_2 ' + styles._modal}>
              {pictures.slice(5).map((picture, index) =>
                <Image
                  onlyFancy
                  imageFancy={getImageUrl(picture, ImageSize.XXL, ImageFormat.JPG)}
                  useFancy={'gallery'}
                  key={index}
                />
              )}
            </div>
          }
        </>
      }
    </div>
  );
}

export default ImageGallery;